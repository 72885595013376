import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Link, useNavigate } from "react-router-dom";
import { useStateContext } from "../contexts/AuthContextProvider";
import axiosClient from "../axios-client";
import iziToast from "izitoast";
import { useTranslation } from "../TranslationContext";

const Login = () => {

  const { translateText, language } = useTranslation();
  const [translatedText, setTranslatedText] = useState({});
  const defaultText = {
    successMessage: "Login Successful",
    errorMessage: "Invalid Email or password",
    login: "Login",
    welcomeBack: "Welcome Back",
    email: "Email",
    enterEmail:  "Enter Email",
    password: "Password",
    enterPassword:  "Enter Password",
    forgotPassword: "Forgot Password", 
    accountHaveOrNot: "Don't have an Account?",
    register: "Register",
  
  
  };

  // const translatePageContent = async () => {
  //   const translated = {

  //     successMessage: await translateText("Login Successful"),
  //     errorMessage: await translateText("Invalid Email or password"),
  //     login: await translateText("Login"),
  //     welcomeBack: await translateText("Welcome Back"),
  //     email: await translateText("Email"),
  //     enterEmail:  await translateText("Enter Email"),
  //     password: await translateText("Password"),
  //     enterPassword:  await translateText("Enter Password"),
  //     forgotPassword: await translateText("Forgot Password"), 
  //     accountHaveOrNot: await translateText("Don't have an Account?"),
  //     register: await translateText("Register"),

  //   };
  //   setTranslatedText(translated);
  // };

  const translatePageContent = async () => {
    const translations = await Promise.all(
      Object.keys(defaultText).map(async key => ({
        [key]: await translateText(defaultText[key])
      }))
    );

    const translated = Object.assign({}, ...translations);
    setTranslatedText(translated);
  };

  useEffect(() => {
    translatePageContent();
  }, [language]);

  const [isVisible, setIsVisible] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  
  const userId = localStorage.getItem('userId');

  const { setToken, setUser, apipath } = useStateContext();
  const handleVisibility = () => {
    setIsVisible(!isVisible);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    try {
     
      const { data } = await axiosClient.post("/login", { email, password });
      setUser( data.user);
  
      setToken(data.token);
      if(data.status === "false"){
        iziToast.error({
          message: data.message,
          position: "topCenter"
        });
      }
      else{
        iziToast.success({
          message: translatedText.successMessage,
          position: "topCenter"
        });

        navigate("/location-detail");


      }
     
    } catch (err) {
      iziToast.error({
        message: translatedText.errorMessage,
        position: "topCenter"
      });
      setError( translatedText.errorMessage);
      console.error(err);
    }
  };

  return (
    <>
      <Navbar />
      <Link to="/" className="float">
        <img src="images-local/help.png" alt="" />
      </Link>
      <div className="container-fluid location">
        <div className="container login">
          <div className="row">
            <div className="col-lg-6 login-left">
              <div className="l-logo">
                <img src="images-local/logo2.png" alt="" />
              </div>
              <h1>{translatedText.login}</h1>
              <h6>{translatedText.welcomeBack}</h6>
              <form onSubmit={handleSubmit} className="login-form">
                <div className="login-item">
                  <label htmlFor="">{translatedText.email}</label>
                  <input
                    type="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder={translatedText.enterEmail}
                  />
                </div>
                <div className="login-item">
                  <label htmlFor="">{translatedText.password}</label>
                  <div className="login-group">
                    <input
                      type={isVisible ? "text" : "password"}
                      id="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder={translatedText.enterPassword}
                    />
                    <span>
                      <i
                        className={isVisible ? "bi bi-eye-slash" : "bi bi-eye"}
                        onClick={handleVisibility}
                      ></i>
                    </span>
                  </div>
                  <Link
                    to="/forgot"
                    className="text-decoration-none text-white"
                  >
                    <p>{translatedText.forgotPassword}?</p>
                  </Link>
                </div>
          
                {error && <p style={{ color: "#ff8780" }}>{error}</p>}
                <div className="login-btn">
                  <button type="submit">{translatedText.login}</button>
                </div>
                <p className="register">
                  {translatedText.accountHaveOrNot}{" "}
                  <Link to="/register" className="text-decoration-none">
                    <span>{translatedText.register}</span>{" "}
                  </Link>
                </p>
              </form>
            </div>
            <div className="col-lg-6 login-img">
              <img src="images-local/phone.png" alt="" />
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Login;
