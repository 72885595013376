import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useStateContext } from "../contexts/AuthContextProvider";
import axiosClient from "../axios-client";
import iziToast from "izitoast";
import { useTranslation } from "../TranslationContext";

const Plan = () => {

  const { translateText, language } = useTranslation();
  const [translatedText, setTranslatedText] = useState({});
  const defaultText = {
    errorMessage: "This field should not be blank",
    secondErrorMessage: "Please enter a valid email address",
    successMessage: "Registered Successfully and your email password sent to your mail",
    thirdErrorMessage: "Error signing up, please try again",
    locationFound: "Location Found ",
    locatedDevice: "We have located the device with number",
    trialPack: "Our Trial Pack",  
    firstList: "Get a full report on",
    secondList: "Look up numbers & usernames",
    thirdList: "Send anonymous voice messages",
    forthList: "Get detailed insights about any phone number",
    price: "Price",
    getPreciseLocation: "Sign up to get precise location",
    email: "Email",
    enterEmail: "Enter Email",
    continue: "Continue",
  
  };

  // const translatePageContent = async () => {
  //   const translated = {
  //     errorMessage: await translateText("This field should not be blank"),
  //     secondErrorMessage: await translateText("Please enter a valid email address"),
  //     successMessage: await translateText("Registered Successfully and your email password sent to your mail"),
  //     thirdErrorMessage: await translateText("Error signing up, please try again"),
  //     register: await translateText("Register"),
  //     locationFound: await translateText("Location Found "),
  //     locatedDevice: await translateText("We have located the device with number"),
  //     trialPack: await translateText("Our Trial Pack"),  
  //     firstList: await translateText("Get a full report on"),
  //     secondList: await translateText("Look up numbers & usernames"),
  //     thirdList: await translateText("Send anonymous voice messages"),
  //     forthList: await translateText("Get detailed insights about any phone number"),
  //     price: await translateText("Price"),
  //     getPreciseLocation: await translateText("Sign up to get precise location"),     
  //     email: await translateText("Email"),
  //     enterEmail: await translateText("Enter Email"),
  //     continue: await translateText("Continue"),
    
   
  //   };
  //   setTranslatedText(translated);
  // };

  const translatePageContent = async () => {
    const translations = await Promise.all(
      Object.keys(defaultText).map(async key => ({
        [key]: await translateText(defaultText[key])
      }))
    );

    const translated = Object.assign({}, ...translations);
    setTranslatedText(translated);
  };
  



  useEffect(() => {
    translatePageContent();
  }, [language]);

  const location = useLocation();
  const { phone, code } = location.state || {};

  const { setToken, setUser } = useStateContext();

  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [trialPrice, setTrialPrice] = useState(null);
  const navigate = useNavigate();

  const fetchPricing = async () => {
    try {
      const { data } = await axiosClient.get("/subscription/prices");
      setTrialPrice(data.trial_price);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchPricing();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email) {
      setError(translatedText.errorMessage);
      return;
    } else if (!emailRegex.test(email)) {
      setError(translatedText.secondErrorMessage);
      return;
    }

    try {
      const { data } = await axiosClient.post("/signup", { email });
      setUser(data.user);
      setToken(data.token);
      if (data.status === "false") {
        iziToast.error({
          message: data.message,
          position: "topCenter",
        });
      } else {
        iziToast.success({
          message: translatedText.successMessage,
          position: "topCenter",
        });
        navigate("/payment");
      }
    } catch (err) {
      const response = err.response;
      if (response && response.status === 422) {
        if (response.data.errors && response.data.errors.email) {
          setError(response.data.errors.email[0]);
        } else {
          setError(translatedText.thirdErrorMessage);
        }
      } else {
        setError(translatedText.thirdErrorMessage);
      }
      console.error(err);
    }
  };
  return (
    <>
      <Navbar />
      <Link to="/" className="float">
        <img src="images-local/help.png" alt="" />
      </Link>
      <div className="container-fluid location">
        <div className="container location-1">
          <div className="col-lg-10 mx-auto location-2">
            <div className="plan-header">
              <h5>{translatedText.locationFound} ! ! !</h5>
              <h2>
                {translatedText.locatedDevice} <br />
                <span>
                  {code}-{phone}
                </span>
              </h2>
            </div>
            <div className="row plan-row">
              <div className="col-lg-6">
                <div className="plan-left">
                  <h5>{translatedText.trialPack} :</h5>
                  <div>
                    <li>
                     {translatedText.firstList} {code}-{phone}
                    </li>
                    <li>{translatedText.secondList}</li>
                    <li>{translatedText.thirdList}</li>
                    <li>{translatedText.forthList}</li>
                  </div>
                  <div className="px-4 mt-4">
                    <div className="loc-text">
                      <span>{translatedText.price}</span>
                      <span>TL {trialPrice ? trialPrice : "Loading..."}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="plan-right">
                  <h5>{translatedText.getPreciseLocation} :</h5>
                  <form onSubmit={handleSubmit} className="login-form">
                    <div className="login-item">
                      <label htmlFor="email">{translatedText.email}</label>
                      <input
                        type="email"
                        name="email"
                        id="email"
                        placeholder={translatedText.enterEmail}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    {error && <p style={{ color: "#ff8780" }}>{error}</p>}

                    <div className="login-btn">
                      <button type="submit">{translatedText.continue}</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Plan;
