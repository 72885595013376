import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useStateContext } from "../contexts/AuthContextProvider";
import axiosClient from "../axios-client";
import iziToast from "izitoast";
import { useTranslation } from "../TranslationContext";

const Profile = () => {
  const { translateText, language } = useTranslation();
  const [translatedText, setTranslatedText] = useState({});
  const defaultText = {
    errorMessage: "Error fetching subscription details:",
    mainHeading: "My Profile",
    locading: "Loading...",
    firstButton: "Account",
    secondButton: "Subscription",
    thirdButton: "History",
    secondHeading: "Personal Information",
    forthButton: "Edit",
    firstField: "Name",
    enterFirstField: "Enter Full Name",
    secondField: "Phone No.",
    enterSecondField: "Enter phone no",
    thirdField: "Email",
    enterThirdField: "Enter Email",
    forthField: "Address",
    enterForthField: "Enter Address",
    fifthField: "City",
    enterFifthField: "Enter City",
    sixthField: "State",
    enterSixthField: "Enter State",
    seventhField: "Country",
    enterSeventhFiled: "Enter Country",
    fifthButton: "Update",
    eightField: "Plan",
    ninthField: "Joined",
    thirdHeading: "Your Plan is expired...",
    sixthButton: "Pay",
    forthHeading: "Your Plan is not active...",
    subcriptionType: "Subscription Type",
    vaildTill: "Valid till",
    fifthHeading: "Located on",

  };

  // const translatePageContent = async () => {
  //   const translated = {
   
  //   errorMessage: await translateText("Error fetching subscription details:"),
  //   mainHeading: await translateText("My Profile"),
  //   firstButton: await translateText("Account"),
  //   secondButton: await translateText("Subscription"),
  //   thirdButton: await translateText("History"),
  //   locading: await translateText("Loading..."),
  //   secondHeading: await translateText("Personal Information"),
  //   forthButton: await translateText("Edit"),
  //   firstField: await translateText("Name"),
  //   enterFirstField: await translateText("Enter Full Name"),
  //   secondField: await translateText("Phone Number"),
  //   enterSecondField: await translateText("Enter phone number"),
  //   thirdField: await translateText("Email"),
  //   enterThirdField: await translateText("Enter Email"),
  //   forthField: await translateText("Address"),
  //   enterForthField: await translateText("Enter Address"),
  //   fifthField: await translateText("City"),
  //   enterFifthField: await translateText("Enter City"),
  //   sixthField: await translateText("State"),
  //   enterSixthField: await translateText("Enter State"),
  //   seventhField: await translateText("Country"),
  //   enterSeventhFiled: await translateText("Enter Country"),
  //   fifthButton: await translateText("Update"),
  //   eightField: await translateText("Plan"),
  //   ninthField: await translateText("Joined"),
  //   thirdHeading: await translateText("Your Plan is expired"),
  //   sixthButton: await translateText("Pay"),
  //   forthHeading: await translateText("Your Plan is not active"),
  //   subcriptionType: await translateText("Subscription Type"),
  //   vaildTill: await translateText("Valid till"),
  //   fifthHeading: await translateText("Located on"),

  //   };
  //   setTranslatedText(translated);
  // };

  const translatePageContent = async () => {
    const translations = await Promise.all(
      Object.keys(defaultText).map(async key => ({
        [key]: await translateText(defaultText[key])
      }))
    );

    const translated = Object.assign({}, ...translations);
    setTranslatedText(translated);
  };
  



  useEffect(() => {
    translatePageContent();
  }, [language]);

  const { apipath, token, user } = useStateContext();
  const navigate = useNavigate();
  const location = useLocation();
  const [profileTab, setProfileTab] = useState("account");
  const [edit, setEdit] = useState(false);
  const [phoneHistory, setPhoneHistory] = useState([]);
  const [subscription, setSubscription] = useState(null);
  const [subscriptionExpired, setSubscriptionExpired] = useState(false);
  const [paymentNotFound, setPaymentNotFound] = useState(false);
  const [loading, setLoading] = useState(true);

  const getSubscription = async () => {
    try {
      const { data } = await axiosClient.get("/packages-details", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (data.subscription_type) {
        const today = new Date();
        const expiryDate = new Date(data.expiry_date);

        if (expiryDate < today) {
          setSubscriptionExpired(true);
          setSubscription(null);
        } else {
          setSubscription({
            type: data.subscription_type,
            expiryDate: data.expiry_date,
            joined: data.joined,
          });
          setSubscriptionExpired(false);
        }
      } else {
        setPaymentNotFound(true);
      }
    } catch (err) {
      console.error(translatedText.errorMessage, err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getSubscription();
  }, []);

  const [profile, setProfile] = useState({
    name: "",
    email: "",
    phone: "",
    city: "",
    state: "",
    country: "",
    address: "",
    created_at: "",
    plan: "",
  });
  const [userprofile, setUserProfile] = useState({
    name: "",
    email: "",
    phone: "",
    city: "",
    state: "",
    country: "",
    address: "",
    created_at: "",
  });

  const handleChange = (e) => {
    setUserProfile({ ...userprofile, [e.target.name]: e.target.value });
  };

  const handleProfileTab = (tab) => {
    setProfileTab(profileTab === tab ? null : tab);
  };

  const getProfile = async () => {
    try {
      const { data } = await axiosClient.get("/profile", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setProfile(data);
      setUserProfile(data);
    } catch (err) {
      console.log(err);
    }
  };

  const userData = async () => {
    try {
      const { data } = await axiosClient.get("/fetch-phone-history", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setPhoneHistory(data.data.history);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    userData();
    getProfile();
  }, []);

  const handleSubmit = async (e) => {
    try {
      const { data } = await axiosClient.post("/update-profile", userprofile, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(data);
      if (data.success) {
        iziToast.success({
          message: data.success,
          position: "topCenter",
        });
        getProfile();
        setEdit(!edit);
      } else {
        iziToast.error({
          message: data.message,
          position: "topCenter",
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    console.log(phoneHistory);
  }, [phoneHistory]);

  return (
    <>
      <Navbar />
      <Link to="/" className="float">
        <img src="images-local/help.png" alt="" />
      </Link>
      <div className="container-fluid location">
        <div className="container location-1">
          <div className="col-lg-10 mx-auto location-2 p-5">
            <div className="plan-header">
              <h2>{translatedText.mainHeading}</h2>
            </div>
            <div className="profile-tabs">
              <button
                className={`profile-links ${
                  profileTab === "account" ? "profile-active" : ""
                }`}
                onClick={() => handleProfileTab("account")}
              >
               {translatedText.firstButton}
              </button>
              <button
                className={`profile-links ${
                  profileTab === "subscription" ? "profile-active" : ""
                }`}
                onClick={() => handleProfileTab("subscription")}
              >
               {translatedText.secondButton}
              </button>
              <button
                className={`profile-links ${
                  profileTab === "history" ? "profile-active" : ""
                }`}
                onClick={() => handleProfileTab("history")}
              >
                {translatedText.thirdButton}
              </button>
            </div>

            {profileTab === "account" && (
              <div>
                <div className="profile-top">
                  <h5> {translatedText.secondHeading}</h5>
                  <span onClick={() => setEdit(!edit)}> {translatedText.forthButton}</span>
                </div>
                {edit ? (
                  <div className="row login-form mt-4">
                    <div className="col-lg-6">
                      <div className="login-item">
                        <label htmlFor=""> {translatedText.firstField}</label>
                        <input
                          type="text"
                          name="name"
                          id=""
                          onChange={handleChange}
                          value={userprofile.name}
                          placeholder={translatedText.enterFirstField}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="login-item">
                        <label htmlFor="">{translatedText.secondField}</label>
                        <input
                          type="text"
                          name="phone"
                          id=""
                          onChange={handleChange}
                          value={userprofile.phone}
                          placeholder={translatedText.enterSecondField}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="login-item">
                        <label htmlFor="">{translatedText.thirdField}</label>
                        <input
                          type="email"
                          name="email"
                          id=""
                          onChange={handleChange}
                          value={userprofile.email}
                          placeholder={translatedText.enterThirdField}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="login-item">
                        <label htmlFor="">{translatedText.forthField}</label>
                        <input
                          type="text"
                          name="address"
                          id=""
                          onChange={handleChange}
                          value={userprofile.address}
                          placeholder={translatedText.enterForthField}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="login-item">
                        <label htmlFor="">{translatedText.fifthField}</label>
                        <input
                          type="text"
                          name="city"
                          id=""
                          onChange={handleChange}
                          value={userprofile.city}
                          placeholder={translatedText.enterFifthField}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="login-item">
                        <label htmlFor="">{translatedText.sixthField}</label>
                        <input
                          type="text"
                          name="state"
                          id=""
                          onChange={handleChange}
                          value={userprofile.state}
                          placeholder={translatedText.enterSixthField}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="login-item">
                        <label htmlFor="">{translatedText.seventhField}</label>
                        <input
                          type="text"
                          name="country"
                          id=""
                          onChange={handleChange}
                          value={userprofile.country}
                          placeholder={translatedText.enterSeventhFiled}
                        />
                      </div>
                    </div>
                    <div className="contact-btn">
                      <button onClick={() => handleSubmit()}>{translatedText.fifthButton}</button>
                    </div>
                  </div>
                ) : (
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="profile-item">
                        <h6>{translatedText.firstField}</h6>
                        <p>{profile.name}</p>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="profile-item">
                        <h6>{translatedText.secondField}</h6>
                        <p>{profile.phone}</p>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="profile-item">
                        <h6>{translatedText.thirdField}</h6>
                        <p>{profile.email}</p>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="profile-item">
                        <h6>{translatedText.forthField}</h6>
                        <p>{profile.address}</p>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="profile-item">
                        <h6>{translatedText.fifthField}</h6>
                        <p>{profile.city}</p>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="profile-item">
                        <h6>{translatedText.sixthField}</h6>
                        <p>{profile.state}</p>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="profile-item">
                        <h6>{translatedText.seventhField}</h6>
                        <p>{profile.country}</p>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="profile-item">
                        <h6>{translatedText.eightField}</h6>
                        <p>{profile.plan}</p>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="profile-item">
                        <h6>{translatedText.ninthField}</h6>
                        <p>{profile.created_at}</p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
       
            {profileTab === "subscription" && (
              <div>
                <div className="profile-top">
                  <h5>{translatedText.secondButton}</h5>
                </div>
                {loading ? (
                  <div className="loading">{translatedText.loadingHeading}</div>
                ) : subscriptionExpired ? (
                  <div className="profile-top">
                    <h5>{translatedText.thirdHeading}</h5>
                    <Link
                      to="/payment"
                      className="text-decoration-none text-white"
                    >
                      <span>{translatedText.sixthButton}</span>
                    </Link>
                  </div>
                ) : paymentNotFound ? (
                  <div className="profile-top">
                    <h5>{translatedText.forthHeading}</h5>
                    <Link
                      to="/payment"
                      className="text-decoration-none text-white"
                    >
                      <span>{translatedText.sixthButton}</span>
                    </Link>
                  </div>
                ) : subscription && subscription.type ? (
                  <div>
                    <div className="profile-top">
                      <h5>{translatedText.secondButton}</h5>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="how-card">
                          <h6>{translatedText.subcriptionType}: {subscription.type}</h6>
                          <p>{translatedText.vaildTill}: {subscription.expiryDate}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            )}

            {profileTab === "history" && (
              <div>
                <div className="profile-top">
                  <h5>{translatedText.fifthHeading}</h5>
                </div>
                <div className="row">
                  {phoneHistory.map((phistory) => {
                    const phone = phistory.phone_number;
                    const code = phistory.country_code || "+91";
                    const formattedDate = new Date(
                      phistory.created_at
                    ).toLocaleString("en-US", {
                      year: "numeric", 
                      month: "long", 
                      day: "numeric", 
                      hour: "2-digit", 
                      minute: "2-digit", 
                      second: "2-digit",
                    });
                    return (
                      <div className="col-lg-6">
                        <div
                          className="history-card"
                          onClick={() =>
                            navigate("/location-detail", {
                              state: { phone, code },
                            })
                          }
                        >
                          <div>
                            <h6>
                              {phistory.country_code}-{phistory.phone_number}
                            </h6>
                            <p>{translatedText.fifthHeading} {formattedDate}</p>
                          </div>
                          <i className="bi bi-info-circle"></i>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Profile;
