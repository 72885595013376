import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Link, useNavigate } from "react-router-dom";
import { useStateContext } from "../contexts/AuthContextProvider";
import axiosClient from "../axios-client";
import axios from "axios";
import iziToast from "izitoast";
import { useTranslation } from "../TranslationContext";

const Forgot = () => {

  
  const { translateText, language } = useTranslation();
  const [translatedText, setTranslatedText] = useState({});
  const defaultText = {
    forgotPassword: "Forgot Password ?",
    noIssue: "No issue, we can help you",
    email: "Email",
    continue: "Continue",
    rememberedPassword: "Remembered Password?",
    login: "Login",
    pleaseCheck: "Please check your email for the reset link.",
    somethingWrong: "Something went wrong. Please try again.",

  };

  // const translatePageContent = async () => {
  //   const translated = {
  //     forgotPassword: await translateText("Forgot Password "),
  //     noIssue:  await translateText("No issue, we can help you"),
  //     email: await translateText("Email"),
  //     continue: await translateText("Continue"),
  //     rememberedPassword: await translateText("Remembered Password?"),
  //     login: await translateText("Login"),
  //     pleaseCheck: await translateText("Please check your email for the reset link."),
  //     somethingWrong: await translateText("Something went wrong. Please try again."),

  //   };
  //   setTranslatedText(translated);
  // };
  const translatePageContent = async () => {
    const translations = await Promise.all(
      Object.keys(defaultText).map(async key => ({
        [key]: await translateText(defaultText[key])
      }))
    );

    const translated = Object.assign({}, ...translations);
    setTranslatedText(translated);
  };
  useEffect(() => {
    translatePageContent();
  }, [language]);

  const navigate = useNavigate()
  const {  apipath } = useStateContext();
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const reset = "reset";

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage("");
    setError("");
    try {
      const response = await axiosClient.post("/forgot-password", {
        email: email,
      });

      if (response.data.status === "true") {
        setMessage(translatedText.pleaseCheck);
        iziToast.success({
          message: translatedText.pleaseCheck,
          position: "topCenter"
        });
        navigate("/otp", { state: { email, reset } });
      } else {
        iziToast.error({
          message: translatedText.somethingWrong,
          position: "topCenter"
        });
        setError(translatedText.somethingWrong);
      }
    } catch (err) {
      setError("Error: " + err.response?.data?.message || err.message);
    }
  };

  return (
    <>
      <Navbar />
      <Link to="/" className="float">
        <img src="images-local/help.png" alt="Help" />
      </Link>
      <div className="container-fluid location">
        <div className="container login">
          <div className="row">
            <div className="col-lg-6 login-left">
              <div className="l-logo">
                <img src="images-local/logo2.png" alt="Logo" />
              </div>
              <h1>{translatedText.forgotPassword }</h1>
              <h6>{translatedText.noIssue }</h6>
              <form onSubmit={handleSubmit} className="login-form">
                <div className="login-item">
                  <label htmlFor="email">{translatedText.email }</label>
                  <input
                    type="email"
                    id="email"
                    placeholder="Enter email"
                    value={email}
                    onChange={handleEmailChange}
                    required
                  />
                </div>
              
                <div className="login-btn">
                  <button type="submit">{translatedText.continue }</button>
                </div>
                {message && <p className="success-message">{message}</p>}
                {error && <p className="error-message">{error}</p>}
                <p className="register">
                {translatedText.rememberedPassword }{" "}
                  <Link to="/login" className="text-decoration-none">
                    <span>{translatedText.login }</span>
                  </Link>
                </p>
              </form>
            </div>
            <div className="col-lg-6 login-img">
              <img src="images-local/phone.png" alt="Phone" />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Forgot;
