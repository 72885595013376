import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Link, useNavigate } from "react-router-dom";
import { useStateContext } from "../contexts/AuthContextProvider";
import axiosClient from "../axios-client";
import iziToast from "izitoast";
import axios from "axios";
import { useTranslation } from "../TranslationContext";

const Tanitum = () => {
  const { translateText, language } = useTranslation();
  const [translatedText, setTranslatedText] = useState({});
  const defaultText = {
    thirdErrorMessage: "Please Select Code.",
    forthErrorMessage: "Please enter valid phone number.",
    errorMessage: "An error occurred while fetching details.",
    enterPhone: "Enter Phone Number",
    mainHeading:
      "Never Lose Track Again. Effortless Location Tracking Made Simple.",
    firstPara:
      "Never waste time searching for misplaced valuables again. Our     location tracking solution helps you find what matters most, from  your phone to your furry friend, instantly.",
    login: "Login",
    register: "Register",
    subkeyword: "How",
    siteName: "Locaradar",
    secondSubKeyword: "works",
    secondPara:
      "Risus commodo id odio turpis pharetra elementum. Pulvinar porta  porta feugiat scelerisque in elit. Morbi rhoncus, tellus, eros consequat magna semper orci a tincidunt.",
    createAccount: "Create Your Account",
    thirdPara:
      "Egestas tellus nunc proin amet tellus tinciduntl acus  consequat. Ultrices",
    trackingWorkHow: "How Tracking Works",
    trackNumber: "Track a phone number Right Now with",
    trackNumberSubPara: "3 easy steps",
    locationRequest: "Request Location",
    enterNumberToLocate: "Enter the phone number you want to locate.",
    videoTagMessage: "Your browser does not support the video tag.",
    bestHeading: "Some of Our best",
    featureHeading: "Features",
    forthPara:
      "Risus commodo id odio turpis pharetra elementum. Pulvinar  porta porta feugiat scelerisque in elit. Morbi rhoncus,  tellus, eros consequat magna semper orci a tincidunt.",
    showMoreButton: "SHOW MORE",
    locationByLink: "Location By Link",
    readMoreLink: "Read More",
    emailPhoneChecker: "Email & Phone Leak checker",
    getLocationWithSteps: "Get location with easy steps",
    phoneBasicDetails: "Phone Number Basic details",
    sendLocationRequest: "Send Location request",
    wantToKnowLocation: "Want to know the",
    location: "Location",
    fifthPara:
      "Locaradar is a trustworthy phone tracker by number that can find individuals no matter where they are.",
    locateNow: "Locate Now",
    locateButton: "Locate",
    mostAsked: "Frequently Asked",
    questions: "Questions",
    firstQuestions: "Can I track someone by cell phone number?",
    FirstAnswer:
      "Ut enim ad minim veniam quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat aute irure dolor",
  };

  // const translatePageContent = async () => {
  //   const translated = {
  //     thirdErrorMessage: await translateText("Please Select Code."),
  //     forthErrorMessage: await translateText(
  //       "Please enter valid phone number."
  //     ),
  //     errorMessage: await translateText(
  //       "An error occurred while fetching details."
  //     ),
  //     mainHeading: await translateText(
  //       "Never Lose Track Again. Effortless Location Tracking Made Simple."
  //     ),
  //     enterPhone: await translateText("Enter Phone Number"),

  //     firstPara: await translateText(
  //       "Never waste time searching for misplaced valuables again. Our location tracking solution helps you find what matters most, from  your phone to your furry friend, instantly."
  //     ),
  //     login: await translateText("Login"),
  //     register: await translateText("Register"),
  //     subkeyword: await translateText("How"),
  //     siteName: await translateText("Locaradar"),
  //     secondSubKeyword: await translateText("works"),
  //     secondPara: await translateText(
  //       "Risus commodo id odio turpis pharetra elementum. Pulvinar porta porta feugiat scelerisque in elit. Morbi rhoncus, tellus, eros consequat magna semper orci a tincidunt."
  //     ),
  //     createAccount: await translateText("Create Your Account"),
  //     thirdPara: await translateText(
  //       "Egestas tellus nunc proin amet tellus tinciduntl acus  consequat. Ultrices"
  //     ),

  //     trackingWorkHow: await translateText("How Tracking Works"),
  //     trackNumber: await translateText("Track a phone number Right Now with"),
  //     trackNumberSubPara: await translateText("3 easy steps"),
  //     locationRequest: await translateText("Request Location"),
  //     enterNumberToLocate: await translateText(
  //       "Enter the phone number you want to locate."
  //     ),
  //     videoTagMessage: await translateText(
  //       "Your browser does not support the video tag."
  //     ),
  //     bestHeading: await translateText("Some of Our best"),
  //     featureHeading: await translateText("Features"),
  //     forthPara: await translateText(
  //       "Risus commodo id odio turpis pharetra elementum. Pulvinar  porta porta feugiat scelerisque in elit. Morbi rhoncus,  tellus, eros consequat magna semper orci a tincidunt."
  //     ),
  //     showMoreButton: await translateText("SHOW MORE"),
  //     locationByLink: await translateText("Location By Link"),
  //     readMoreLink: await translateText("Read More"),
  //     emailPhoneChecker: await translateText("Email & Phone Leak checker"),
  //     getLocationWithSteps: await translateText("Get location with easy steps"),
  //     phoneBasicDetails: await translateText("Phone Number Basic details"),
  //     sendLocationRequest: await translateText("Send Location request"),
  //     wantToKnowLocation: await translateText("Want to know the"),
  //     location: await translateText("Location"),
  //     fifthPara: await translateText(
  //       "Locaradar is a trustworthy phone tracker by number that can find individuals no matter where they are."
  //     ),
  //     locateNow: await translateText("Locate Now"),
  //     locateButton: await translateText("Locate"),
  //     mostAsked: await translateText("Frequently Asked"),
  //     questions: await translateText("Questions"),
  //     firstQuestions: await translateText(
  //       "Can I track someone by cell phone number?"
  //     ),
  //     FirstAnswer: await translateText(
  //       "Ut enim ad minim veniam quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat aute irure dolor"
  //     ),
  //   };
  //   setTranslatedText(translated);
  // };


  const translatePageContent = async () => {
    const translations = await Promise.all(
      Object.keys(defaultText).map(async key => ({
        [key]: await translateText(defaultText[key])
      }))
    );

    const translated = Object.assign({}, ...translations);
    setTranslatedText(translated);
  };
  useEffect(() => {
    translatePageContent();
  }, [language]);

  const [showFaq, setShowFaq] = useState(1);
  const navigate = useNavigate();
  const [countryCodes, setCountryCodes] = useState([]);

  const { token, apipath } = useStateContext();
  const [phone, setPhone] = useState(null);
  const [code, setCode] = useState("+90");

  const handleClick = () => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  };

  const handleFaqShow = (index) => {
    setShowFaq(showFaq === index ? null : index);
  };

  const handleChange = (e) => {
    setPhone(e.target.value);
  };
  const handleCodeChange = (e) => {
    setCode(e.target.value);
  };

  const findCode = async () => {
    const countrydata = await axios.get("https://restcountries.com/v3.1/all");
    const codes = countrydata.data.flatMap((country) => {
      if (country.idd && country.idd.root && country.idd.suffixes) {
        return country.idd.suffixes.map(
          (suffix) => `${country.idd.root}${suffix}`
        );
      }
      return [];
    });
    setCountryCodes(codes);
    console.log(codes);
  };
  useEffect(() => {
    findCode();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (code === null) {
      iziToast.error({
        message: translatedText.thirdErrorMessage,
        position: "topCenter",
      });
    } else if (
      phone === null ||
      phone.trim() === "" ||
      (phone.length !== 10 && isNaN(phone))
    ) {
      iziToast.error({
        message: translatedText.forthErrorMessage,
        position: "topCenter",
      });
    } else {
      try {
        const response = await axiosClient.get(`/phone-lookup/${code}${phone}`);
        if (response.data.error) {
          iziToast.error({
            message: response.data.error,
            position: "topCenter",
          });
        } else {
          navigate("/location", {
            state: { phone, code, details: response.data },
          });
        }
      } catch (err) {
        console.error(err);
        iziToast.error({
          message: translatedText.errorMessage,
          position: "topCenter",
        });
      }
    }
  };

  return (
    <>
      <Navbar />

      {/* MAIN BANNER */}

      <div className="container-fluid main">
        <div className="container">
          <div className="col-lg-6">
            <h1>{translatedText.mainHeading}</h1>
            <p>{translatedText.firstPara}</p>

            <form className="search-mobile row mt-5">
              <div className="col-lg-8">
                <div className="search-group">
                  <select
                    name=""
                    value={code}
                    onChange={handleCodeChange}
                    id=""
                  >
                    <option value=""></option>
                    {countryCodes.map((code) => (
                      <option key={code} value={code}>
                        {code}
                      </option>
                    ))}
                  </select>
                  <input
                    type="text"
                    onChange={handleChange}
                    maxLength="15"
                    minLength="10"
                    pattern="\d*"
                    placeholder={translatedText.enterPhone}
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <button onClick={handleSubmit}>{translatedText.locateButton}</button>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* HOW IT WORKS */}

      <div className="container-fluid works">
        <div className="container">
          <div className="work-header">
            <h1>
              {translatedText.how} <span>{translatedText.siteName}</span>{" "}
              {translatedText.secondSubKeyword} ?
            </h1>
            <p className="col-lg-7 mx-auto">{translatedText.secondPara} </p>
          </div>
          <div className="row work-row">
            <div className="col-lg-4">
              <div className="work-card">
                <div>
                  <img src="images-local/step-icon.png" alt="" />
                </div>
                <h5>{translatedText.createAccount}</h5>
                <p>{translatedText.thirdPara}</p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="work-card">
                <div>
                  <img src="images-local/step-icon.png" alt="" />
                </div>
                <h5>{translatedText.createAccount}</h5>
                <p>{translatedText.thirdPara}</p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="work-card">
                <div>
                  <img src="images-local/step-icon.png" alt="" />
                </div>
                <h5>{translatedText.createAccount}</h5>
                <p>{translatedText.thirdPara}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* HOW IT WORKS VIDEO */}

      <div className="container-fluid how">
        <div className="container">
          <div className="work-header">
            <h1>
              <span>{translatedText.trackingWorkHow}</span>
            </h1>
          </div>
          <div className="row work-row">
            <div className="col-lg-6 how-left">
              <h5>
                {translatedText.trackNumber}{" "}
                <span>{translatedText.trackNumberSubPara}</span> :
              </h5>
              <div className="how-card">
                <h6>{translatedText.locationRequest}</h6>
                <p>{translatedText.enterNumberToLocate}</p>
              </div>
              <div className="how-card">
                <h6>{translatedText.locationRequest}</h6>
                <p>{translatedText.enterNumberToLocate}</p>
              </div>
              <div className="how-card">
                <h6>{translatedText.locationRequest}</h6>
                <p>{translatedText.enterNumberToLocate}</p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="how-con">
                <div className="how-img">
                  <video width="700" height="520" controls className="video">
                    <source src="/locaradarWorking.mp4" type="video/mp4" />
                    {translatedText.videoTagMessage}
                  </video>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* FEATURES */}

      <div className="container-fluid features">
        <div className="ellipse"></div>
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="feature-left">
                <h1>
                  {translatedText.bestHeading}{" "}
                  <span>{translatedText.featureHeading}</span>
                </h1>
                <p>{translatedText.forthPara} </p>
                <div>
                  <button>{translatedText.showMoreButton}</button>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="feature-card">
                <div>
                  <img src="images-local/location.png" alt="" />
                </div>
                <span>{translatedText.featureHeading}</span>
                <h2>{translatedText.locationByLink}</h2>
                <h6>
                  {translatedText.readMoreLink}{" "}
                  <i className="bi bi-arrow-right"></i>
                </h6>
              </div>
              <div className="feature-card">
                <span>{translatedText.featureHeading}</span>
                <h2>{translatedText.emailPhoneChecker}</h2>
                <h6>
                  {translatedText.readMoreLink}{" "}
                  <i className="bi bi-arrow-right"></i>
                </h6>
              </div>
              <div className="feature-card">
                <span>{translatedText.featureHeading}</span>
                <h2>{translatedText.getLocationWithSteps}</h2>
                <h6>
                  {translatedText.readMoreLink}{" "}
                  <i className="bi bi-arrow-right"></i>
                </h6>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="feature-card">
                <span>{translatedText.featureHeading}</span>
                <h2>{translatedText.phoneBasicDetails}</h2>
                <h6>
                  {translatedText.readMoreLink}{" "}
                  <i className="bi bi-arrow-right"></i>
                </h6>
              </div>
              <div className="feature-card">
                <div>
                  <img src="images-local/location.png" alt="" />
                </div>
                <span>{translatedText.featureHeading}</span>
                <h2>{translatedText.sendLocationRequest}</h2>
                <h6>
                  {translatedText.readMoreLink}{" "}
                  <i className="bi bi-arrow-right"></i>
                </h6>
              </div>
              <div className="feature-card">
                <span>{translatedText.featureHeading}</span>
                <h2>{translatedText.phoneBasicDetails}</h2>
                <h6>
                  {translatedText.readMoreLink}
                  <i className="bi bi-arrow-right"></i>
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* WANT TO KNOW */}

      <div className="container-fluid know">
        <div className="col-lg-6 mx-auto">
          <h1 className="col-lg-10 mx-auto">
            {translatedText.wantToKnowLocation}
            <span> {translatedText.location}</span>
          </h1>
          <p>{translatedText.fifthPara}</p>
          <div className="know-button">
            <Link to="/tanitim" onClick={handleClick}>
              <button>{translatedText.locateNow}</button>
            </Link>
          </div>
        </div>
      </div>

      {/* FAQ */}

      <div className="container-fluid faq">
        <div className="container">
          <h1>
            {translatedText.mostAsked} <span>{translatedText.questions}</span>
          </h1>
          <div className="row faq-row">
            <div className="col-lg-6">
              <div className="faq-card">
                <div className="faq-header">
                  <h4>{translatedText.firstQuestions}</h4>
                  <span
                    className={`span ${showFaq === 1 && "faq-active"}`}
                    onClick={() => {
                      handleFaqShow(1);
                    }}
                  >
                    {showFaq === 1 ? "-" : "+"}
                  </span>
                </div>
                {showFaq === 1 && <p>{translatedText.FirstAnswer}</p>}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="faq-card">
                <div className="faq-header">
                  <h4>{translatedText.firstQuestions}</h4>
                  <span
                    className={`span ${showFaq === 2 && "faq-active"}`}
                    onClick={() => {
                      handleFaqShow(2);
                    }}
                  >
                    {showFaq === 2 ? "-" : "+"}
                  </span>
                </div>
                {showFaq === 2 && <p>{translatedText.FirstAnswer}</p>}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="faq-card">
                <div className="faq-header">
                  <h4>{translatedText.firstQuestions}</h4>
                  <span
                    className={`span ${showFaq === 3 && "faq-active"}`}
                    onClick={() => {
                      handleFaqShow(3);
                    }}
                  >
                    {showFaq === 3 ? "-" : "+"}
                  </span>
                </div>
                {showFaq === 3 && <p>{translatedText.FirstAnswer}</p>}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="faq-card">
                <div className="faq-header">
                  <h4>{translatedText.firstQuestions}</h4>
                  <span
                    className={`span ${showFaq === 4 && "faq-active"}`}
                    onClick={() => {
                      handleFaqShow(4);
                    }}
                  >
                    {showFaq === 4 ? "-" : "+"}
                  </span>
                </div>
                {showFaq === 4 && <p>{translatedText.FirstAnswer}</p>}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="faq-card">
                <div className="faq-header">
                  <h4>{translatedText.firstQuestions}</h4>
                  <span
                    className={`span ${showFaq === 5 && "faq-active"}`}
                    onClick={() => {
                      handleFaqShow(5);
                    }}
                  >
                    {showFaq === 5 ? "-" : "+"}
                  </span>
                </div>
                {showFaq === 5 && <p>{translatedText.FirstAnswer}</p>}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="faq-card">
                <div className="faq-header">
                  <h4>{translatedText.firstQuestions}</h4>
                  <span
                    className={`span ${showFaq === 6 && "faq-active"}`}
                    onClick={() => {
                      handleFaqShow(6);
                    }}
                  >
                    {showFaq === 6 ? "-" : "+"}
                  </span>
                </div>
                {showFaq === 6 && <p>{translatedText.FirstAnswer}</p>}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Tanitum;
