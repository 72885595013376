import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useStateContext } from "../contexts/AuthContextProvider";
import axiosClient from "../axios-client";
import axios from "axios";
import iziToast from "izitoast";
import { useTranslation } from "../TranslationContext";

const Reset = () => {

  const { translateText, language } = useTranslation();
  const [translatedText, setTranslatedText] = useState({});
  const defaultText = {
    successMessage: "Password Reset Successful",
    invalidOTP: "Invalid OTP or OTP expired. Please try again.",
    resetPassword: "Reset Password ",
    newPassword: "New Password",
    confirmPassword: "Confirm Password", 
    continue: "Continue",
    rememberedPassword: "Remembered Password",
    login: "Login",
  
  };

  // const translatePageContent = async () => {
  //   const translated = {

  //     successMessage: await translateText("Password Reset Successful"),
  //     invalidOTP: await translateText("Invalid OTP or OTP expired. Please try again."),
  //     resetPassword: await translateText("Reset Password "),
  //     newPassword: await translateText("New Password"),
  //     confirmPassword: await translateText("Confirm Password"), 
  //     continue: await translateText("Continue"),
  //     rememberedPassword: await translateText("Remembered Password"),
  //     login: await translateText("Login"),

  //   };
  //   setTranslatedText(translated);
  // };

  const translatePageContent = async () => {
    const translations = await Promise.all(
      Object.keys(defaultText).map(async key => ({
        [key]: await translateText(defaultText[key])
      }))
    );

    const translated = Object.assign({}, ...translations);
    setTranslatedText(translated);
  };



  useEffect(() => {
    translatePageContent();
  }, [language]);

  const { apipath } = useStateContext();
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState();
  const location = useLocation();
  const { email } = location.state || {};
  console.log(email);
  const [error, setError] = useState("");
  
  const [userData, setUserData] = useState({
    email: email,
    password: '',
    password_confirmation: '',
  });

  const handleVisibility = (index) => {
    setIsVisible(!isVisible === index ? null : index);
  };

  const handleChange = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");

    try {
      console.log(userData);
        const { data } = await axiosClient.post("/reset-password", userData);
        if(data.status == "true"){
          iziToast.success({
            message: translatedText.successMessage,
            position: "topCenter"
          });
          navigate("/login");
        }
        else{
          iziToast.error({
            message: data.message,
            position: "topCenter"
          });
        }
    } catch (err) {
      console.error(err);
      setError(translatedText.invalidOTP);
    }
  };

  return (
    <>
      <Navbar />
      <Link to="/" className="float">
        <img src="images-local/help.png" alt="" />
      </Link>
      <div className="container-fluid location">
        <div className="container login">
          <div className="row">
            <div className="col-lg-6 login-left">
              <div className="l-logo">
                <img src="images-local/logo2.png" alt="" />
              </div>
              <h1>{translatedText.resetPassword} </h1>
            
              <form  className="login-form">
              
                <div className="login-item">
                  <label htmlFor="">{translatedText.newPassword} </label>
                  <div className="login-group">
                    <input
                      type={isVisible === 1 ? "text" : "password"}
                      name="password"
                      id="password"
                      onChange={handleChange}
                      placeholder={translatedText.newPassword} 
                    />
                    <span>
                      <i
                        className={isVisible ? "bi bi-eye-slash" : "bi bi-eye"}
                        onClick={() => handleVisibility(1)}
                      ></i>
                    </span>
                  </div>
            
                </div>
                <div className="login-item">
                  <label htmlFor="">{translatedText.confirmPassword} </label>
                  <div className="login-group">
                    <input
                      type={isVisible === 2 ? "text" : "password"}
                      name="password_confirmation"
                      onChange={handleChange}
                      id=""
                      placeholder={translatedText.confirmPassword} 
                    />
                    <span>
                      <i
                        className={isVisible ? "bi bi-eye-slash" : "bi bi-eye"}
                        onClick={() => handleVisibility(2)}
                      ></i>
                    </span>
                  </div>
                
                </div>
             
                <div className="login-btn">
                  <button onClick={handleSubmit}>{translatedText.continue} </button>
                </div>
                <p className="register">
                {translatedText.rememberedPassword}  ?{" "}
                  <Link to="/login" className="text-decoration-none">
                    <span>{translatedText.login} </span>{" "}
                  </Link>
                </p>
              </form>
            </div>
            <div className="col-lg-6 login-img">
              <img src="images-local/phone.png" alt="" />
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Reset;
