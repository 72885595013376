import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';

const TranslationContext = createContext();

export const useTranslation = () => {
    return useContext(TranslationContext);
};

export const TranslationProvider = ({ children }) => {
    const [language, setLanguage] = useState(() => {
       
        return localStorage.getItem('language') || 'tr';
    });

    useEffect(() => {
   
        localStorage.setItem('language', language);
    }, [language]);

    const translateText = async (text, targetLanguage = language) => {
        const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;
        const url = `https://translation.googleapis.com/language/translate/v2?key=${apiKey}`;

        try {
            const response = await axios.post(url, {
                q: text,
                target: targetLanguage,
            });

            return response.data.data.translations[0].translatedText;
        } catch (error) {
            console.error('Error with translation:', error);
            return text; // Fallback to original text if there's an error
        }
    };

    return (
        <TranslationContext.Provider value={{ language, setLanguage, translateText }}>
            {children}
        </TranslationContext.Provider>
    );
};
