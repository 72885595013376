import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";
import axiosClient from "../axios-client";
import { useTranslation } from "../TranslationContext";

const Contact = () => {
  // const { translateText, language } = useTranslation();
  // const [translatedText, setTranslatedText] = useState({
  //   firstVaildationMessage: "Name is required",
  //   secondVaildationMessage: "Email is required",
  //   thirdVaildationMessage: "Invalid email address",
  //   forthVaildationMessage: "Phone number is required",
  //   fifthVaildationMessage: "Phone number must be 10-15 digits",
  //   sixthVaildationMessage: "Subject is required",
  //   seventhVaildationMessage: "Message is required",
  //   successMessage: "Message sent successfully",
  //   errorMessage: "Error sending message",
  //   mainHeading: "Contact Us",
  //   subHeading: "We’ll get back to you",

  //   firstField: "Name",
  //   enterfirstField: "Enter Name",
  //   secondField: "Email",
  //   entersecondField: "Enter Email",
  //   thirdField: "Phone no",
  //   enterthirdField: "Enter Phone no",
  //   forthField: "Subject",
  //   enterforthField: "Enter Subject",
  //   fifthField: "Message",
  //   enterfifthField: "Write your message",
  //   send: "Send",
  // });

  // const translatePageContent = async () => {
  //   const translated = {
  //     firstVaildationMessage: await translateText("Name is required"),
  //     secondVaildationMessage: await translateText("Email is required"),
  //     thirdVaildationMessage: await translateText("Invalid email address"),
  //     forthVaildationMessage: await translateText("Phone number is required"),
  //     fifthVaildationMessage: await translateText(
  //       "Phone number must be 10-15 digits"
  //     ),
  //     sixthVaildationMessage: await translateText("Subject is required"),
  //     seventhVaildationMessage: await translateText("Message is required"),
  //     successMessage: await translateText("Message sent successfully"),
  //     errorMessage: await translateText("Error sending message"),
  //     mainHeading: await translateText("Contact Us"),
  //     subHeading: await translateText("We’ll get back to you"),
  //     firstField: await translateText("Name"),
  //     enterfirstField: await translateText("Enter Name"),
  //     secondField: await translateText("Email"),
  //     entersecondField: await translateText("Enter Email"),
  //     thirdField: await translateText("Phone no"),
  //     enterthirdField: await translateText("Enter Phone no"),
  //     forthField: await translateText("Subject"),
  //     enterforthField: await translateText("Enter Subject"),
  //     fifthField: await translateText("Message"),
  //     enterfifthField: await translateText("Write your message"),
  //     send: await translateText("Send"),
  //   };
  //   setTranslatedText(translated);
  // };

  // useEffect(() => {
  //   translatePageContent();
  // }, [language]);

  const { translateText, language } = useTranslation();
  
  const [translatedText, setTranslatedText] = useState({});

  const defaultText = {
    firstVaildationMessage: "Name is required",
    secondVaildationMessage: "Email is required",
    thirdVaildationMessage: "Invalid email address",
    forthVaildationMessage: "Phone number is required",
    fifthVaildationMessage: "Phone number must be 10-15 digits",
    sixthVaildationMessage: "Subject is required",
    seventhVaildationMessage: "Message is required",
    successMessage: "Message sent successfully",
    errorMessage: "Error sending message",
    mainHeading: "Contact Us",
    subHeading: "We’ll get back to you",

    firstField: "Name",
    enterfirstField: "Enter Name",
    secondField: "Email",
    entersecondField: "Enter Email",
    thirdField: "Phone no",
    enterthirdField: "Enter Phone no",
    forthField: "Subject",
    enterforthField: "Enter Subject",
    fifthField: "Message",
    enterfifthField: "Write your message",
    send: "Send",
  };

  const translatePageContent = async () => {
    const translations = await Promise.all(
      Object.keys(defaultText).map(async key => ({
        [key]: await translateText(defaultText[key])
      }))
    );

    const translated = Object.assign({}, ...translations);
    setTranslatedText(translated);
  };

  useEffect(() => {
    translatePageContent();
  }, [language]);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });

  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validate = () => {
    let formErrors = {};

    if (!formData.name.trim()) {
      formErrors.name = translatedText.firstVaildationMessage;
    }

    if (!formData.email.trim()) {
      formErrors.email = translatedText.secondVaildationMessage
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(formData.email)
    ) {
      formErrors.email = translatedText.thirdVaildationMessage;
    }

    if (!formData.phone.trim()) {
      formErrors.phone = translatedText.forthVaildationMessage;
    } else if (!/^\d{10,15}$/.test(formData.phone)) {
      formErrors.phone = translatedText.fifthVaildationMessage;
    }

    if (!formData.subject.trim()) {
      formErrors.subject = translatedText.sixthVaildationMessage;
    }

    if (!formData.message.trim()) {
      formErrors.message = translatedText.seventhVaildationMessage;
    }

    return formErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const validationErrors = validate();
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      axiosClient
        .post("/contact", formData)
        .then((response) => {
          setSuccessMessage(translatedText.successMessage);
          setErrorMessage("");
          setFormData({
            name: "",
            email: "",
            phone: "",
            subject: "",
            message: "",
          });
        })
        .catch((error) => {
          setErrorMessage(translatedText.errorMessage);
          setSuccessMessage("");
        });
    }
  };

  return (
    <>
      <Navbar />

      <Link to="/" className="float">
        <img src="images-local/help.png" alt="" />
      </Link>
      <div className="container-fluid location">
        <div className="container login contact">
          <div>
            <div className="login-left">
              <h1 className="text-center">{translatedText.mainHeading}</h1>
              <h6 className="text-center">{translatedText.subHeading}</h6>
              <form onSubmit={handleSubmit} className="row login-form mt-4">
                <div className="col-lg-6">
                  <div className="login-item">
                    <label htmlFor="name">{translatedText.firstField}</label>
                    <input
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      placeholder={translatedText.enterfirstField}
                    />
                    {errors.name && (
                      <p className="text-danger">{errors.name}</p>
                    )}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="login-item">
                    <label htmlFor="email">{translatedText.secondField}</label>
                    <input
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      placeholder={translatedText.entersecondField}
                    />
                    {errors.email && (
                      <p className="text-danger">{errors.email}</p>
                    )}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="login-item">
                    <label htmlFor="phone">{translatedText.thirdField}</label>
                    <input
                      type="text"
                      name="phone"
                      value={formData.phone}
                      onChange={handleChange}
                      placeholder={translatedText.enterthirdField}
                    />
                    {errors.phone && (
                      <p className="text-danger">{errors.phone}</p>
                    )}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="login-item">
                    <label htmlFor="subject">{translatedText.forthField}</label>
                    <input
                      type="text"
                      name="subject"
                      value={formData.subject}
                      onChange={handleChange}
                      placeholder={translatedText.enterforthField}
                    />
                    {errors.subject && (
                      <p className="text-danger">{errors.subject}</p>
                    )}
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="login-item">
                    <label htmlFor="message">{translatedText.fifthField}</label>
                    <input
                      type="text"
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                      placeholder={translatedText.enterfifthField}
                    />
                    {errors.message && (
                      <p className="text-danger">{errors.message}</p>
                    )}
                  </div>
                </div>

                <div className="login-check">
                  <input type="file" name="file" id="file" />
                </div>
                <div className="contact-btn">
                  <button type="submit">{translatedText.send}</button>
                </div>
              </form>

              {successMessage && (
                <p className="text-success mt-3">{successMessage}</p>
              )}

              {errorMessage && (
                <p className="text-danger mt-3">{errorMessage}</p>
              )}
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Contact;
