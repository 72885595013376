import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useStateContext } from "../contexts/AuthContextProvider";
import { useTranslation } from "../TranslationContext";

const Navbar = () => {
  const navigate = useNavigate();
  const { token, setUser, setToken } = useStateContext();
  const { language, setLanguage, translateText } = useTranslation();
  // const { language, setLanguage } = useTranslation();

  // const { translateText, newlanguage } = useTranslation();
  const [translatedText, setTranslatedText] = useState({});
  const defaultText = {
    firstMenu: "Home",
    secondMenu: "Contact Us",
    thirdMenu: "My Profile",
    forthMenu: "Location Details",
    fifthMenu: "Logout",
    sixthMenu: "  Login/Register",
 
  
  };

  const translatePageContent = async () => {
    const translations = await Promise.all(
      Object.keys(defaultText).map(async key => ({
        [key]: await translateText(defaultText[key])
      }))
    );

    const translated = Object.assign({}, ...translations);
    setTranslatedText(translated);
  };

  useEffect(() => {
    translatePageContent();
  }, [ language]);

  const handleLogout = () => {
    setUser(null);
    setToken(null);
    navigate("/");
  };

  const location = useLocation();

  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const isActive = (path) => {
    return location.pathname === path ? "active" : "";
  };

  const handleLanguageChange = (e) => {
    setLanguage(e.target.value);
  };

  return (
    <nav
      className={`navbar navbar-expand-lg navbar-dark sticky-top ${
        isScrolled ? "scrolled" : ""
      }`}
    >
      <div className="container nav-fluid">
        <a className="navbar-brand" href="/">
          <img src="images-local/logo.png" alt="logo" />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mx-auto">
            <li className="nav-item">
              <Link className={`nav-link ${isActive("/")}`} to="/">
              {translatedText.firstMenu}
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link ${isActive("/contact")}`}
                to="/contact"
              >
                 {translatedText.secondMenu}
              </Link>
            </li>
          </ul>
          <div className="d-flex nav-buttons">
            <select value={language} onChange={handleLanguageChange}>
              <option value="tr">tr</option>
              <option value="en">en</option>

              {/* Add other languages as needed */}
            </select>
            {token ? (
              <div className="nav-item">
                <Link className="nav-link" to="/profile">
                  <span>SA</span> <i className="bi bi-chevron-down"></i>
                </Link>
                <div className="dropdown-menu">
                  <ul>
                    <li>
                      <Link to="/profile">  {translatedText.thirdMenu}</Link>
                    </li>
                    <li>
                      <Link to="/location-detail">  {translatedText.forthMenu}</Link>
                    </li>
                    <li>
                      <Link
                        className="d-flex justify-content-between"
                        onClick={handleLogout}
                      >
                          {translatedText.fifthMenu} <i className="bi bi-box-arrow-right"></i>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            ) : (
              <Link to="/login">
                <button className="first-btn me-3" type="submit">
                {translatedText.sixthMenu}
                </button>
              </Link>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
