import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Link, useNavigate, useLocation } from "react-router-dom";
import OtpTimer from "otp-timer";

import { useStateContext } from "../contexts/AuthContextProvider";
import axiosClient from "../axios-client";
import axios from "axios";
import iziToast from "izitoast";
import { useTranslation } from "../TranslationContext";

const OTP = () => {

  const { translateText, language } = useTranslation();
  const [translatedText, setTranslatedText] = useState({});
  const defaultText = {
    otpVerified: "OTP Verified.",
    invalidOTP: "Invalid OTP or OTP expired. Please try again.",
    otpResent: "OTP resent. Please check your email.",
    errorResendOTP: "Error resending OTP. Please try again.",
    enterOtp: "Enter OTP",
    enterOtpMessage: "Please enter OTP sent to your mail ID",
    resendOtpIn: "Resend OTP In",
    resendOTP: "Resend OTP",
    continue: "Continue",  

  };

  // const translatePageContent = async () => {
  //   const translated = {

  //     otpVerified: await translateText("OTP Verified."),
  //     invalidOTP: await translateText("Invalid OTP or OTP expired. Please try again."),
  //     otpResent: await translateText("OTP resent. Please check your email."),
  //     resendOTP: await translateText("Error resending OTP. Please try again."),
  //     enterOtp: await translateText("Enter OTP"),
  //     enterOtpMessage: await translateText("Please enter OTP sent to your mail ID"),
  //     resendOtpIn: await translateText("Resend OTP In"),
  //     resendOTP: await translateText("Resend OTP"),
  //     continue: await translateText("Continue"),

  //   };
  //   setTranslatedText(translated);
  // };

  const translatePageContent = async () => {
    const translations = await Promise.all(
      Object.keys(defaultText).map(async key => ({
        [key]: await translateText(defaultText[key])
      }))
    );

    const translated = Object.assign({}, ...translations);
    setTranslatedText(translated);
  };

  useEffect(() => {
    translatePageContent();
  }, [language]);
  const { setToken, setUser, apipath } = useStateContext();

  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const { email } = location.state || {};
  const { reset } = location.state || {};

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");

    try {
    //  if(reset){
        const { data } = await axiosClient.post("/verify-reset-otp", { email, otp });
        if(data.status !== "false"){
          iziToast.success({
            message: translatedText.otpVerified,
            position: "topCenter"
          });
        }
  
        navigate("/reset", { state: { email }});
  
    } catch (err) {
      console.error(err);
      iziToast.error({
        message: translatedText.invalidOTP,
        position: "topCenter"
      });
      setError(translatedText.invalidOTP);
    }
  };

  const handleResendOtp = async () => {
    try {
      if(reset){
        email = reset;
      }
      const response = await axiosClient.post("/resend-otp", { email });
      if (response.status === 200) {
        iziToast.success({
          message: translatedText.otpResent,
          position: "topCenter"
        });
       // alert("OTP resent. Please check your email.");
      }
    } catch (err) {
      console.error(err);

      setError(translatedText.errorResendOTP);
    }
  };

  const [isVisible, setIsVisible] = useState(false);

  const handleVisibility = () => {
    setIsVisible(!isVisible);
  };

  const handleOtpChange = (e) => {
    const value = e.target.value;

    if (/^\d*$/.test(value) && value.length <= 6) {
      setOtp(value);
    }
  };
  return (
    <>
      <Navbar />
      <Link to="/" className="float">
        <img src="images-local/help.png" alt="" />
      </Link>
      <div className="container-fluid location">
        <div className="container login">
          <div className="row">
            <div className="col-lg-6 login-left">
              <div className="l-logo">
                <img src="images-local/logo2.png" alt="" />
              </div>
              <h1>{translatedText.enterOtp}</h1>
              <h6>{translatedText.enterOtpMessage}</h6>
              <form onSubmit={handleSubmit} className="login-form">
                <div className="login-item">
                  <label htmlFor="otp">{translatedText.enterOtp}</label>
                  <div className="login-group">
                    <input
                      type={isVisible ? "text" : "password"}
                      name="otp"
                      id="otp"
                      value={otp}
                      placeholder="Enter OTP"
                      onChange={handleOtpChange}
                    />
                    <span>
                      <i
                        className={isVisible ? "bi bi-eye-slash" : "bi bi-eye"}
                        onClick={handleVisibility}
                      ></i>
                    </span>
                  </div>
                  <p>
                    <OtpTimer
                      minutes={0}
                      seconds={60}
                      text={
                        <span className="otp-timer-text">{translatedText.resendOtpIn}: </span>
                      }
                      ButtonText={
                        <span className="otp-timer-button">{translatedText.resendOTP}</span>
                      }
                      textColor={"#fff"}
                      buttonColor={"#fff"}
                      background={"transparent"}
                      resend={handleResendOtp}
                    />
                  </p>
                </div>
                {error && <p style={{ color: "#ff8780" }}>{error}</p>}

                <div className="login-btn">
                  <button type="submit">{translatedText.continue}</button>
                </div>
              </form>
            </div>
            <div className="col-lg-6 login-img">
              <img src="images-local/phone.png" alt="" />
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default OTP;
