import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import { useStateContext } from "../contexts/AuthContextProvider";
import { useTranslation } from "../TranslationContext";
import axiosClient from "../axios-client";
import axios from "axios";
import iziToast from "izitoast";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png",
  iconUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png",
  shadowUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png",
});

const LocationDetail = () => {
  const { translateText, language } = useTranslation();
  const [translatedText, setTranslatedText] = useState({});
  const defaultText = {
    firstErrorMessage: "Location access is Pending.",
    secondErrorMessage: "Location access is Denied by User.",
    thirdErrorMessage: "Please Select Code.",
    forthErrorMessage: "Please enter valid phone number.",
    mainHeading: "Find location of ",
    enterNumber: "Enter Phone Number",
    locate: "Locate",
    details: "Details",
    recent: "Recent",
    career: "Carrier",
    loadingLookupData: "Loading phone lookup data",
    countryField: "Country",
    cityField: "City",
    timeZoneField: "Time Zone",
    addressField: "Address",
    locatedOn: "Located on",
    viewButton: "View All",

  };

  // const translatePageContent = async () => {
  //   const translated = {

  //     firstErrorMessage: await translateText("Location access is Pending."),
  //     secondErrorMessage: await translateText("Location access is Denied by User."),
  //     thirdErrorMessage: await translateText("Please Select Code."),
  //     forthErrorMessage: await translateText("Please enter valid phone number."),
  //     mainHeading: await translateText("Find location of "),
  //     enterNumber: await translateText("Enter Phone Number"),
  //     locate: await translateText("Locate"),
  //     details: await translateText("Details"),
  //     recent: await translateText("Recent"),
  //     career: await translateText("Carrier"),
  //     loadingLookupData: await translateText("Loading phone lookup data"),
  //     countryField: await translateText("Country"),
  //     cityField: await translateText("City"),
  //     timeZoneField: await translateText("Time Zone"),
  //     addressFiled: await translateText("Address"),
  //     locatedOn: await translateText("Located on"),
  //     viewButton: await translateText("View All"),
  //   };
  //   setTranslatedText(translated);
  // };

  const translatePageContent = async () => {
    const translations = await Promise.all(
      Object.keys(defaultText).map(async key => ({
        [key]: await translateText(defaultText[key])
      }))
    );

    const translated = Object.assign({}, ...translations);
    setTranslatedText(translated);
  };

  useEffect(() => {
    translatePageContent();
  }, [language]);
  const [profileTab, setProfileTab] = useState("details");
  const { apipath, token, user } = useStateContext();
  const navigate = useNavigate();
  const [countryCodes, setCountryCodes] = useState(["+90"]);
  const location = useLocation();

  const { code = "+90" } = location.state || {};
  const { phone = "" } = location.state || {};
  const [newphone, setPhone] = useState(null);
  const [newcode, setCode] = useState(code);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [ophone, setOphone] = useState(phone);
  const [phoneHistory, setPhoneHistory] = useState({});
  const [phoneLookupHistory, setPhoneLookupHistory] = useState(null);
  const [lookupCompleted, setLookupCompleted] = useState(false);

  const [userLocation, setUserLocation] = useState({
    country: "",
    city: "",
    full_address: "",
    longitude: "",
    latitude: "",
    timezone: "",
  });

  const historyData = async () => {
    try {
      const { data } = await axiosClient.get("/fetch-phone-history", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(data);
      setPhoneHistory(data.data.history);
    } catch (err) {
      console.log(err);
    }
  };

  const phoneLookupData = async () => {
    try {
      const { data } = await axiosClient.get(
        `/phone-lookup/${newcode}${ophone}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setPhoneLookupHistory(data.carrier);
      setLookupCompleted(true);
    } catch (err) {
      console.log(err);
      setLookupCompleted(false);
    }
  };
  useEffect(() => {
    historyData();
    phoneLookupData();
  }, []);

  useEffect(() => {
    console.log(phoneHistory);
  }, [phoneHistory]);

  const userData = async () => {
    if (phone) {
      try {
        const { data } = await axiosClient.post(
          "/fetch-location",
          {
            phone_number: phone,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log(data);
        if (data.status === "pending") {
          iziToast.error({
            message: translatedText.firstErrorMessage,
            position: "topCenter",
          });
        } else if (data.status === "false") {
          iziToast.error({
            message: translatedText.secondErrorMessage,
            position: "topCenter",
          });
        }
        setUserLocation(data);
        setLatitude(data.latitude);
        setLongitude(data.longitude);
      } catch (err) {
        console.log(err);
      }
    }
  };

  useEffect(() => {
    userData();
  }, [phone]);

  const handleChange = (e) => {
    setPhone(e.target.value);
    setOphone(e.target.value);
  };
  const handleCodeChange = (e) => {
    setCode(e.target.value);
  };
  const findCode = async () => {
    const countrydata = await axios.get("https://restcountries.com/v3.1/all");
    const codes = countrydata.data.flatMap((country) => {
      if (country.idd && country.idd.root && country.idd.suffixes) {
        return country.idd.suffixes.map(
          (suffix) => `${country.idd.root}${suffix}`
        );
      }
      return [];
    });
    setCountryCodes(codes);
  };
  useEffect(() => {
    findCode();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newcode === null) {
      iziToast.error({
        message: translatedText.thirdErrorMessage,
        position: "topCenter",
      });
    } else if (newphone.length !== 10 && isNaN(newphone)) {
      iziToast.error({
        message: translatedText.forthErrorMessage,
        position: "topCenter",
      });
    } else {
      try {
        console.log(newphone);
        console.log(newcode);
        const { data } = await axiosClient.post(
          "/send-location-link",
          {
            phone_number: newphone,
            country_code: newcode,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log(data);
        if (data.status !== "false") {
          iziToast.success({
            message: data.message,
            position: "topCenter",
          });
          var phone = newphone;
          var code = newcode;
          navigate("/location-detail", { state: { phone, code } });
        } else {
          iziToast.error({
            message: data.message,
            position: "topCenter",
          });
        }
      } catch (err) {
        console.error(err);
      }
    }
  };
  if (latitude) {
    console.log(latitude, longitude);
    var mapcontainer = (
      <>
        <MapContainer
          center={[latitude, longitude]}
          zoom={16}
          style={{
            height: "100vh",
            width: "100%",
          }}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" // Using OpenStreetMap tiles
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          <Marker position={[latitude, longitude]}>
            <Popup>
              latitude: {latitude}, longitude: {longitude}
            </Popup>
          </Marker>
        </MapContainer>
      </>
    );
  } else {
    mapcontainer = "";
  }

  const handleProfileTab = (tab) => {
    setProfileTab(profileTab === tab ? null : tab);
  };

  return (
    <>
      <Navbar />

      <Link to="/" className="float">
        <img src="images-local/help.png" alt="" />
      </Link>

      <div
        className="container-fluid"
        style={{ position: "relative", "min-height": "100vh" }}
      >
        {mapcontainer}
      
        <div
          className="col-lg-4"
          style={{
            position: "absolute",
            left: "70px",
            top: "20px",
            "z-index": "999",
          }}
        >
          <div className="detail-search">
            <h5>{translatedText.mainHeading}:</h5>
            <form className="search-location row" onSubmit={handleSubmit}>
              <div className="col-lg-8">
                <div className="location-group">
                  {lookupCompleted ? (
                    <option value={newcode}>{newcode}</option>
                  ) : (
                    <select name="" onChange={handleCodeChange} id="">
                      <option value=""></option>
                      {countryCodes.map((pcode) => (
                        <option
                          key={pcode}
                          value={pcode}
                          selected={pcode == code}
                        >
                          {pcode}
                        </option>
                      ))}
                    </select>
                  )}
                  {lookupCompleted ? (
                    <input type="text" value={ophone} />
                  ) : (
                    <input
                      type="text"
                      onChange={handleChange}
                      value={ophone}
                      maxLength="10"
                      minLength="10"
                      placeholder={translatedText.mainHeading}
                      required
                    />
                  )}
                </div>
              </div>
              <div className="col-lg-4">
                {!lookupCompleted && <button type="submit">{translatedText.locate}</button>}
              </div>
            </form>
          </div>
          <div className="detail-search">
            <h5>{translatedText.mainHeading} :</h5>
            <div className="profile-tabs">
              <button
                className={`profile-links ${
                  profileTab === "details" ? "profile-active" : ""
                }`}
                onClick={() => handleProfileTab("details")}
              >
                {translatedText.details}
              </button>
              <button
                className={`profile-links ${
                  profileTab === "recent" ? "profile-active" : ""
                }`}
                onClick={() => handleProfileTab("recent")}
              >
                {translatedText.recent}
              </button>
            </div>
            {profileTab === "details" && (
              <div>
                <div className="row p-4">
              
                  <div className="col-lg-12">
                    <div className="profile-item">
                      <h6>{translatedText.career}</h6>
                      {phoneLookupHistory ? (
                        <p> {phoneLookupHistory}</p>
                      ) : (
                        <p>{translatedText.loadingLookupData}...</p>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="profile-item">
                      <h6>{translatedText.countryField}</h6>
                      <p>{userLocation.country}</p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="profile-item">
                      <h6>{translatedText.cityField}</h6>
                      <p>{userLocation.city}</p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="profile-item">
                      <h6>{translatedText.timeZoneField}</h6>
                      <p>{userLocation.timezone}</p>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="profile-item">
                      <h6>{translatedText.addressField}</h6>
                      <p>{userLocation.full_address}</p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="footer-social">
                      <Link>
                        <div className="social-img">
                          <img src="images-local/instagram.png" alt="" />
                        </div>
                      </Link>
                      <Link>
                        <div className="social-img">
                          <img src="images-local/facebook.png" alt="" />
                        </div>
                      </Link>
                      <Link>
                        <div className="social-img">
                          <img src="images-local/tinder.png" alt="" />
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {profileTab === "recent" && (
              <div>
                <div className="row mt-4">
                  {phoneHistory.map((phistory) => {
                    const phone = phistory.phone_number;
                    const code = phistory.country_code || "+90";
                    const formattedDate = new Date(
                      phistory.created_at
                    ).toLocaleString("en-US", {
                      year: "numeric", // Year (e.g., '2024')
                      month: "long", // Month name (e.g., 'August')
                      day: "numeric", // Day of the month (e.g., '29')
                      hour: "2-digit", // Hour (e.g., '09')
                      minute: "2-digit", // Minute (e.g., '05')
                      second: "2-digit", // Second (e.g., '30')
                    });
                    return (
                      <div className="col-lg-12">
                        <div
                          className="history-card"
                          onClick={() =>
                            navigate("/location-detail", {
                              state: { phone, code },
                            })
                          }
                        >
                          <div>
                            <h6>
                              {phistory.country_code}-{phistory.phone_number}
                            </h6>
                            <p>{translatedText.locatedOn} {formattedDate}</p>
                          </div>
                          <i className="bi bi-info-circle"></i>
                        </div>
                      </div>
                    );
                  })}
                 
                  <p className="view">
                    <Link to="/profile" className="text-white">
                      {translatedText.viewButton}
                    </Link>
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
        {/* </div>
        </div> */}
      </div>

      <Footer />
    </>
  );
};

export default LocationDetail;
