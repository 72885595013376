import React, { useEffect, useState } from "react";
import { FaLock } from "react-icons/fa";
import { Link, useLocation, useParams } from "react-router-dom";
import axiosClient from "../axios-client";
import iziToast from "izitoast";
import { useTranslation } from "../TranslationContext";

const Access = () => {
  const { translateText, language } = useTranslation();
  const [translatedText, setTranslatedText] = useState({});
  const defaultText = {
    firstErrorMessage: "Location Access Denied.",
    requestAccessMessage: "Requesting location access...",
    successMessage: "Location access granted",
    secondErrorMessage: "Error occurred while fetching location",
    thirdErrorMessage: "Geolocation is not supported by this browser.",
    forthErrorMessage: "Geolocation not supported",
    mainHeading: "Grant Access",
    subHeading: "Do you allow us to access your location",
    deny: "Deny",
    allow: "Allow",
  };

  // const translatePageContent = async () => {
  //   const translated = {
   
  //     firstErrorMessage: await translateText("Location Access Denied."),
  //     requestAccessMessage: await translateText("Requesting location access..."),
  //     successMessage: await translateText("Location access granted"),
  //     secondErrorMessage: await translateText("Error occurred while fetching location"),
  //     thirdErrorMessage: await translateText("Geolocation is not supported by this browser."),
  //     forthErrorMessage: await translateText("Geolocation not supported"),
  //     mainHeading: await translateText("Grant Access"),
  //     subHeading: await translateText("Do you allow us to access your location"),
  //     deny: await translateText("Deny"),
  //     allow: await translateText("Allow"),


  //   };
  //   setTranslatedText(translated);
  // };
  const translatePageContent = async () => {
    const translations = await Promise.all(
      Object.keys(defaultText).map(async key => ({
        [key]: await translateText(defaultText[key])
      }))
    );

    const translated = Object.assign({}, ...translations);
    setTranslatedText(translated);
  };

  useEffect(() => {
    translatePageContent();
  }, [language]);

  const params = useLocation();
  const [location, setLocation] = useState({ latitude: null, longitude: null });
  const [status, setStatus] = useState("");
  const [error, setError] = useState(null);
  const searchParams = new URLSearchParams(params.search);
  const phone = searchParams.get("phone");
  const email = searchParams.get("email");
  const id = searchParams.get("id");

  const denyLocation = async () => {
    try {
      const userData = {
        location_id: id,
        status: "false",
      };
      console.log(userData);
      const { data } = await axiosClient.post("/save-location", userData);
      console.log(data);
      if (data.status !== "false") {
      } else {
        iziToast.error({
          message: data.message,
          position: "topCenter",
        });
      }
    } catch (err) {
      console.error(err);
    }
    iziToast.error({
      message: translatedText.firstErrorMessage,
      position: "topCenter",
    });
  };

  const getLocation = () => {
    if (navigator.geolocation) {
      setStatus(translatedText.requestAccessMessage);
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          setLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
          try {
            const userData = {
              location_id: id,
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
              status: "true",
            };
            console.log(userData);
            const { data } = await axiosClient.post("/save-location", userData);
            console.log(data);
            if (data.status !== "false") {
              iziToast.success({
                message: data.message,
                position: "topCenter",
              });
            } else {
              iziToast.error({
                message: data.message,
                position: "topCenter",
              });
            }
          } catch (err) {
            console.error(err);
          }

          setStatus(translatedText.successMessage);
          setError(null);
        },
        async (err) => {
          iziToast.error({
            message: translatedText.firstErrorMessage,
            position: "topCenter",
          });

          try {
            const userData = {
              location_id: id,
              status: "false",
            };
            console.log(userData);
            const { data } = await axiosClient.post("/save-location", userData);
            console.log(data);
            if (data.status !== "false") {
            } else {
              iziToast.error({
                message: data.message,
                position: "topCenter",
              });
            }
          } catch (err) {
            console.error(err);
          }
          setError(err.message);
          if (err.code === err.PERMISSION_DENIED) {
          
            iziToast.error({
              message: translatedText.firstErrorMessage,
              position: "topCenter",
            });
            setStatus(translatedText.firstErrorMessage);
          } else {
         
            setStatus(translatedText.secondErrorMessage);
          }
        }
      );
    } else {
      setError(translatedText.thirdErrorMessage);
      setStatus(translatedText.forthErrorMessage);
    }
  };

  return (
    <div className="overlay">
      <div className="request-access-box">
        <FaLock className="access-icon" />
        <h2>{translatedText.mainHeading}</h2>
        <p>{translatedText.subHeading}?</p>
        <div className="prompt-btn">
          <button onClick={denyLocation} className="deny-button">
            {translatedText.deny}
          </button>
          <button onClick={getLocation} className="access-button">
          {translatedText.allow}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Access;
