// import './App.css';
// import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// import Landing from './pages/Landing';
// import Location from './pages/Location';
// import Login from './pages/Login';
// import Forgot from './pages/Forgot';
// import Reset from './pages/Reset';
// import Register from './pages/Register';
// import OTP from './pages/OTP';
// import Payment from './pages/Payment';
// import Plan from './pages/Plan';
// import Contact from './pages/Contact';
// import Profile from './pages/Profile';
// import LocationDetail from './pages/LocationDetail';
// import Cookie from './pages/Cookie';
// import Privacy from './pages/Privacy';
// import Terms from './pages/Terms';
// import Access from './pages/Access';
// import Tanitum from './pages/Tanitum';
// import PrivateRoute from './components/PrivateRoute';
// import GoogleTranslate from './GoogleTranslate';

// function App() {
//   return (
//     <div className="App">
//       <GoogleTranslate />
//      <Router>
//         <Routes>
//         <Route exact path="/" element={<Landing />} />
//         <Route exact path="/tanitim" element={<Tanitum />} />
        
//         <Route exact path="/location" element={<Location />} />
//         <Route exact path="/login" element={<Login />} />
//         <Route exact path="/forgot" element={<Forgot />} />
//         <Route exact path="/reset" element={<Reset />} />
//         <Route exact path="/register" element={<Register />} />
//         <Route exact path="/otp" element={<OTP />} />
//         <Route exact path="/payment" element={<PrivateRoute element={<Payment />} />} />
//         {/* <Route exact path="/payment" element={<Payment />} /> */}
//         <Route exact path="/plan" element={<Plan />} />
//         <Route exact path="/contact" element={<Contact />} />
//         <Route exact path="/profile" element={<PrivateRoute element={<Profile />} />} />
//         {/* <Route exact path="/profile" element={<Profile />} /> */}
//         <Route exact path="/location-detail" element={<PrivateRoute element={<LocationDetail />} />} />
//         {/* <Route exact path="/location-detail" element={<LocationDetail />} /> */}
//         <Route exact path="/cookie-policy" element={<Cookie />} />
//         <Route exact path="/privacy-policy" element={<Privacy />} />
//         <Route exact path="/tnc" element={<Terms />} />
//         <Route exact path="/access" element={<Access />} />
//         </Routes>
//         </Router>
//     </div>
//   );
// }

// export default App;


import './App.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Landing from './pages/Landing';
import Location from './pages/Location';
import Login from './pages/Login';
import Forgot from './pages/Forgot';
import Reset from './pages/Reset';
import Register from './pages/Register';
import OTP from './pages/OTP';
import Payment from './pages/Payment';
import Plan from './pages/Plan';
import Contact from './pages/Contact';
import Profile from './pages/Profile';
import LocationDetail from './pages/LocationDetail';
import Cookie from './pages/Cookie';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';
import Access from './pages/Access';
import Tanitum from './pages/Tanitum';
import PrivateRoute from './components/PrivateRoute';
import { TranslationProvider } from './TranslationContext'; 


function App() {
  return (
    <TranslationProvider>
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Landing />} />
    
          <Route path="/tanitim" element={<Tanitum />} />
          <Route path="/location" element={<Location />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgot" element={<Forgot />} />
          <Route path="/reset" element={<Reset />} />
          <Route path="/register" element={<Register />} />
          <Route path="/otp" element={<OTP />} />
          <Route path="/payment" element={<PrivateRoute element={Payment} />} />
          <Route path="/plan" element={<Plan />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/profile" element={<PrivateRoute element={Profile} />} />
          <Route path="/location-detail" element={<PrivateRoute element={LocationDetail} />} />
          <Route path="/cookie-policy" element={<Cookie />} />
          <Route path="/privacy-policy" element={<Privacy />} />
          <Route path="/tnc" element={<Terms />} />
          <Route path="/access" element={<Access />} />
        </Routes>
      </Router>
    </div>
    </TranslationProvider>
  );
}

export default App;

