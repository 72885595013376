import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { useTranslation } from "../TranslationContext";

const Privacy = () => {
  const { translateText, language } = useTranslation();
  const [translatedText, setTranslatedText] = useState({});
  const defaultText = {
    firstHeading: "Privacy Notice",
    firstPara:
      "The last update of this Privacy Notice was made on 24 of January, 2024",
    secondPara: `This Privacy Notice describes the privacy practices for the Website Locaradar (hereinafter "Website", "Site") operated by AVIWELL LIMITED (hereinafter “we”, “us”, “our”, “Company”) and how the Website and Locaradar, operated by Company, collect and use the personal data you provide to the Company, with the purpose to use Locaradar (hereinafter referred to as the “Locaradar”). It also describes the choices available to you regarding our use of your personal information and how you can access, update and delete this information.`,
    thirdPara:
      "The use of the Website is possible without any indication of personal data; however, if a user wants to use the Locaradar,  collecting and processing of personal data could become necessary.  If the processing of personal data is necessary, we generally obtain consent from the user as a data subject, except cases when personal data processing is available under other grounds (legitimate  interests, contract executing, etc.).",
    forthPara:
      " The processing of personal data, such as the address, e-mail address, telephone number or billing information of a data subject  shall always be in line with the General Data Protection Regulation  (GDPR), and in accordance with the country-specific data protection  regulations applicable to Company. By means of this Privacy Notice, we would like to inform the general public of the nature, scope, and  purpose of the personal data we collect, use and process. Furthermore, data subjects (users) are informed, by means of this Privacy Notice, of the rights to which they are entitled.",
    fifthPara: "This Privacy Notice is an integral part of the Terms of Use",
    fifthSubPara:
      "As the controller, Company has implemented numerous technical and organizational measures to ensure the most complete protection of  personal data processed through this Website. In this Privacy Notice AVIWELL LIMITED (registered address: Nikou Pattichi, 36, 3071,  Limassol, Cyprus, HE 432943) is identified as the Controller of  personal data.",
    secondHeading: "COOKIES",
    sixthPara:
      "A cookie is a small text file which is placed onto your device (e.g.computer, smartphone or other electronic device) when you visit our website to store a range of information, for example, your language preference, or browser and device you are using to view the website. Those cookies are set by us and called first-party cookies. We also use third-party cookies, which are cookies from a domain different from the domain of our website, for our advertising and marketing efforts.",
    seventhPara:
      "Session cookies expire each time you close your browser and do not remain on your device afterwards. These cookies allow our website to link your actions during a particular browser session. ",
    seventhSubPara:
      "Persistent cookies will remain on your device for a period of time, and will expire on a set expiration date, or when you delete them manually from your cache. These cookies are stored on your device in between browser sessions and allow your preferences and actions across our website to be remembered.",
    thirdHeading: "DEFINITIONS",
    eightHeadingPara: "Personal data",
    eightPara:
      "means any information relating to an identified or identifiable natural person (“data subject/user”). An identifiable natural person is one who can be identified, directly or indirectly, in particular by reference to an identifier such as a name, an identification number, location data, an online identifier or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity of that natural person.",
    ninthHeadingPara: "Controller",
    ninthPara:
      "for the purposes of the General Data Protection Regulation (GDPR), other data protection laws provisions related to data protection is the Company.",
    tenthHeadingPara: "Processing",
    tenthPara:
      "is any operation or set of operations which is performed on personal data or on sets of personal data, whether or not by automated means, such as collection, recording, organization, structuring, storage, adaptation or alteration, retrieval, consultation, use, disclosure by transmission, dissemination or otherwise making available, alignment or combination, restriction, erasure or destruction.",
    eleventhHeadingPara: "Pseudonymization",
    eleventhPara:
      "is the processing of personal data in such a manner that the personal data can no longer be attributed to a specific data subject/user without the use of additional information, provided that such additional information is kept separately and is subject to technical and organizational measures to ensure that the personal data are not attributed to an identified or identifiable natural person.",
    twelethHeadingPara: "Encryption",
    twelethPara:
      "is a security protection measure for personal data; as a form of cryptography, it is a process whereby personal data gets turned into an encoded and unintelligible version, using encryption algorithms and an encryption key, and whereby a decryption key or code enables users to decode it again.",
    thirteenHeadingPara: "Consent",
    thirteenPara:
      "of the data subject/user is any freely given, specific, informed and unambiguous indication of the data subject/user`s wishes by which he or she, by a statement or by a clear affirmative action, signifies agreement to the processing of personal data relating to him or her.",
    forteenHeading: "PERSONAL DATA COLLECTION AND USE",
    forteenPara:
      "Anyone can access our Website without necessity to provide its personal data. ",
    forteenSubPara:
      "The purpose of processing your personal data by the Company and collection of information about you are as follows (the “Purpose”):",
    firstList: "Your intention to use Locaradar;",
    secondList:
      " For the performance of a contract to which the user is a party, in accordance with Article 6.1.b of the GDPR and the collected data are necessary in order for Locaradar to identify and bill the user and to charge its bank card number;",
    thirdList:
      "For verifying your identity and providing customer care  service and assistance;",
    forthList:
      "Analyze your use of our Website and Locaradar to better understand how they are being used so we can improve our Services and the user experience, and engage and retain users.",
    fifteenPara:
      "We may use your Personal data to: (a) improve your browsing experience by personalizing the Website and to improve the Locaradar; (b) send information to you by email regarding registration status, password verification, payment confirmation; (c) send you communications relating to your use of the Locaradar, and (d) provide our partners with statistical information about our users by secured channels under data processing agreements (DPA); (e) send you marketing and promotional materials and messages.",
    sixteenPara:
      "As you are willing to use Locaradar you are required to  provide your personal data (to register an account and purchase  the Locaradar), thus becoming a user of Locaradar.",
    sixteenSubPara:
      "Please be aware that you are not obliged to provide us with your  personal data. However, if you do not provide us with your email  address and payment information, you may not be able to create  an account to log into and make a purchase of Locaradar.",
    fifteenHeading:
      "SCOPE AND CATEGORIES OF PERSONAL DATA, LAWFUL BASIS FOR PERSONAL DATA PROCESSING AND DATA SUBJECT CATEGORIES",
    seventeenPara:
      "We strive to be compliant with GDPR and implemented data minimization principles.",
    tableFirstHeading: "No.",
    tableSeconndHeading: "Scope and categories of personal data",
    tableThirdHeading: "Data subject categories",
    tableForthHeading: "Processing purpose",
    tableFifthHeading: "Lawful basis for personal data processing",
    firstRowFruid: "__cfruid",
    firstRowParty: "3d party",
    firstRowSite: "cloudflare.com",
    firstRowCookie:
      "This cookie is used by service Cloud Flare and required to manage incoming traffic and to have better visibility on the origin of a particular request",
    firstRowSession: "Session",
    eighteenPara:
      "The storage of this data takes place against the background that this is the only way to prevent the misuse of Locaradar, and, if necessary, to make it possible to investigate committed offenses.",
    eighteenSubFirstPara:
      "The personal data are not transferred to third parties, except events, when it’s necessary with an aim to provide Locaradar and/or there is a lawful obligation to transfer these data under request of government authorities and/or the transferring is executed in respect of criminal proceedings. At the same time, taking into account the principles of data protection and the provisions of personal data protection laws, you understand, acknowledge and accept that we have the right, and sometimes the lawful obligation, to provide the holder of the telephone number whose location is being sought (target device holder) by you with the following information about you (as a Locaradar user) upon request of such holder: IP address; Email; Browser information (Browser name, version); Device type (Web, Mob, Laptop); OS (Windows, Android, MacOS); Browser language; Device screen size.",
    eighteenSubSecondPara: `We do not authorize the use of your personal data by any third party (only under exceptional conditions as described under "Legal Matters" below). We operate and maintain a variety of online security measures to safeguard and keep your personal information private and secured.`,
    eighteenSubThirdPara:
      "When you purchase the Locaradar you have to fill the User’s Information, which should contain your personal information as email, which is processed and stored by us",
    eighteenSubForthPara:
      "You also have to fill the Payment Information, which should contain your Personal data, which is partly processed by us and processed by the payment providers.",
    sixteenHeading: "MISCELLANEOUS",
    nineteenPara:
      "We may modify this Privacy Notice at any time and post any changes to the Privacy Notice on the Website, so please review it frequently. We indicate the date of the current version of this Privacy Notice below, so you know when it was last updated.",
    nineteenSubFirstPara:
      "Changes to this Privacy Notice may not affect your personal data we have previously collected from you or after such changes.",
    nineteenSubSecondPara:
      "If you object to the changes, please contact us as provided below.",
    nineteenSubThirdPara:
      "This Privacy Notice is governed by and construed with the laws of Cyprus.",
    nineteenSubForthPara:
      "The Cyprus courts shall have exclusive jurisdiction to settle any claim or dispute which might arise out of or in connection with this Privacy Notice.",
    nineteenSubFifthPara:
      "If you have any questions or propositions, please, get in touch with us by the e-mail.",
  };

  // const translatePageContent = async () => {
  //   const translated = {
  //     firstHeading: await translateText("Privacy Notice"),
  //     firstPara: await translateText(
  //       "The last update of this Privacy Notice was made on 24 of January, 2024"
  //     ),
  //     secondPara: await translateText(
  //       `This Privacy Notice describes the privacy practices for the Website Locaradar (hereinafter "Website", "Site") operated by AVIWELL LIMITED (hereinafter “we”, “us”, “our”, “Company”) and how the Website and Locaradar, operated by Company, collect and use the personal data you provide to the Company, with the purpose to use Locaradar (hereinafter referred to as the “Locaradar”). It also describes the choices available to you regarding our use of your personal information and how you can access, update and delete this information.`
  //     ),
  //     thirdPara: await translateText(
  //       "The use of the Website is possible without any indication of personal data; however, if a user wants to use the Locaradar,  collecting and processing of personal data could become necessary.  If the processing of personal data is necessary, we generally obtain consent from the user as a data subject, except cases when personal data processing is available under other grounds (legitimate  interests, contract executing, etc.)."
  //     ),
  //     forthPara: await translateText(
  //       " The processing of personal data, such as the address, e-mail address, telephone number or billing information of a data subject  shall always be in line with the General Data Protection Regulation  (GDPR), and in accordance with the country-specific data protection  regulations applicable to Company. By means of this Privacy Notice, we would like to inform the general public of the nature, scope, and  purpose of the personal data we collect, use and process. Furthermore, data subjects (users) are informed, by means of this Privacy Notice, of the rights to which they are entitled."
  //     ),
  //     fifthPara: await translateText(
  //       "This Privacy Notice is an integral part of the Terms of Use"
  //     ),
  //     fifthSubPara: await translateText(
  //       "As the controller, Company has implemented numerous technical and organizational measures to ensure the most complete protection of  personal data processed through this Website. In this Privacy Notice AVIWELL LIMITED (registered address: Nikou Pattichi, 36, 3071,  Limassol, Cyprus, HE 432943) is identified as the Controller of  personal data."
  //     ),
  //     secondHeading: await translateText("COOKIES"),
  //     sixthPara: await translateText(
  //       "A cookie is a small text file which is placed onto your device (e.g.computer, smartphone or other electronic device) when you visit our website to store a range of information, for example, your language preference, or browser and device you are using to view the website. Those cookies are set by us and called first-party cookies. We also use third-party cookies, which are cookies from a domain different from the domain of our website, for our advertising and marketing efforts."
  //     ),
  //     seventhPara: await translateText(
  //       "Session cookies expire each time you close your browser and do not remain on your device afterwards. These cookies allow our website to link your actions during a particular browser session."
  //     ),
  //     seventhSubPara: await translateText(
  //       "Persistent cookies will remain on your device for a period of time, and will expire on a set expiration date, or when you delete them manually from your cache. These cookies are stored on your device in between browser sessions and allow your preferences and actions across our website to be remembered."
  //     ),
  //     thirdHeading: await translateText("DEFINITIONS"),
  //     eightHeadingPara: await translateText("Personal data"),
  //     eightPara: await translateText(
  //       "means any information relating to an identified or identifiable natural person (“data subject/user”). An identifiable natural person is one who can be identified, directly or indirectly, in particular by reference to an identifier such as a name, an identification number, location data, an online identifier or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity of that natural person."
  //     ),
  //     ninthHeadingPara: await translateText("Controller"),
  //     ninthPara: await translateText(
  //       "for the purposes of the General Data Protection Regulation (GDPR), other data protection laws provisions related to data protection is the Company."
  //     ),
  //     tenthHeadingPara: await translateText("Processing"),
  //     tenthPara: await translateText(
  //       "is any operation or set of operations which is performed on personal data or on sets of personal data, whether or not by automated means, such as collection, recording, organization, structuring, storage, adaptation or alteration, retrieval, consultation, use, disclosure by transmission, dissemination or otherwise making available, alignment or combination, restriction, erasure or destruction."
  //     ),
  //     eleventhHeadingPara: await translateText("Pseudonymization"),
  //     eleventhPara: await translateText(
  //       "is the processing of personal data in such a manner that the personal data can no longer be attributed to a specific data subject/user without the use of additional information, provided that such additional information is kept separately and is subject to technical and organizational measures to ensure that the personal data are not attributed to an identified or identifiable natural person."
  //     ),
  //     twelethHeadingPara: await translateText("Encryption"),
  //     twelethPara: await translateText(
  //       "is a security protection measure for personal data; as a form of cryptography, it is a process whereby personal data gets turned into an encoded and unintelligible version, using encryption algorithms and an encryption key, and whereby a decryption key or code enables users to decode it again."
  //     ),
  //     thirteenHeadingPara: await translateText("Consent"),
  //     thirteenPara: await translateText(
  //       "of the data subject/user is any freely given, specific, informed and unambiguous indication of the data subject/user`s wishes by which he or she, by a statement or by a clear affirmative action, signifies agreement to the processing of personal data relating to him or her."
  //     ),
  //     forteenHeading: await translateText("PERSONAL DATA COLLECTION AND USE"),
  //     forteenPara: await translateText(
  //       "Anyone can access our Website without necessity to provide its personal data."
  //     ),
  //     forteenSubPara: await translateText(
  //       "The purpose of processing your personal data by the Company and collection of information about you are as follows (the “Purpose”):"
  //     ),
  //     firstList: await translateText("Your intention to use Locaradar;"),
  //     secondList: await translateText(
  //       " For the performance of a contract to which the user is a party, in accordance with Article 6.1.b of the GDPR and the collected data are necessary in order for Locaradar to identify and bill the user and to charge its bank card number;"
  //     ),
  //     thirdList: await translateText(
  //       "For verifying your identity and providing customer care  service and assistance;"
  //     ),
  //     forthList: await translateText(
  //       "Analyze your use of our Website and Locaradar to better understand how they are being used so we can improve our Services and the user experience, and engage and retain users."
  //     ),
  //     fifteenPara: await translateText(
  //       "We may use your Personal data to: (a) improve your browsing experience by personalizing the Website and to improve the Locaradar; (b) send information to you by email regarding registration status, password verification, payment confirmation; (c) send you communications relating to your use of the Locaradar, and (d) provide our partners with statistical information about our users by secured channels under data processing agreements (DPA); (e) send you marketing and promotional materials and messages."
  //     ),
  //     sixteenPara: await translateText(
  //       "As you are willing to use Locaradar you are required to  provide your personal data (to register an account and purchase  the Locaradar), thus becoming a user of Locaradar."
  //     ),
  //     sixteenSubPara: await translateText(
  //       "Please be aware that you are not obliged to provide us with your  personal data. However, if you do not provide us with your email  address and payment information, you may not be able to create  an account to log into and make a purchase of Locaradar."
  //     ),
  //     fifteenHeading: await translateText(
  //       "SCOPE AND CATEGORIES OF PERSONAL DATA, LAWFUL BASIS FOR PERSONAL DATA PROCESSING AND DATA SUBJECT CATEGORIES"
  //     ),
  //     seventeenPara: await translateText(
  //       "We strive to be compliant with GDPR and implemented data minimization principles."
  //     ),
  //     tableFirstHeading: await translateText("No."),
  //     tableSeconndHeading: await translateText(
  //       "Scope and categories of personal data"
  //     ),
  //     tableThirdHeading: await translateText("Data subject categories"),
  //     tableForthHeading: await translateText("Processing purpose"),
  //     tableFifthHeading: await translateText(
  //       "Lawful basis for personal data processing"
  //     ),
  //     firstRowFruid: await translateText("__cfruid"),
  //     firstRowParty: await translateText("3d party"),
  //     firstRowSite: await translateText("cloudflare.com"),
  //     firstRowCookie: await translateText(
  //       "This cookie is used by service Cloud Flare and required to manage incoming traffic and to have better visibility on the origin of a particular request"
  //     ),
  //     firstRowSession: await translateText("Session"),
  //     eighteenPara: await translateText(
  //       "The storage of this data takes place against the background that this is the only way to prevent the misuse of Locaradar, and, if necessary, to make it possible to investigate committed offenses."
  //     ),
  //     eighteenSubFirstPara: await translateText(
  //       "The personal data are not transferred to third parties, except events, when it’s necessary with an aim to provide Locaradar and/or there is a lawful obligation to transfer these data under request of government authorities and/or the transferring is executed in respect of criminal proceedings. At the same time, taking into account the principles of data protection and the provisions of personal data protection laws, you understand, acknowledge and accept that we have the right, and sometimes the lawful obligation, to provide the holder of the telephone number whose location is being sought (target device holder) by you with the following information about you (as a Locaradar user) upon request of such holder: IP address; Email; Browser information (Browser name, version); Device type (Web, Mob, Laptop); OS (Windows, Android, MacOS); Browser language; Device screen size."
  //     ),
  //     eighteenSubSecondPara: await translateText(
  //       `We do not authorize the use of your personal data by any third party (only under exceptional conditions as described under "Legal Matters" below). We operate and maintain a variety of online security measures to safeguard and keep your personal information private and secured.`
  //     ),
  //     eighteenSubThirdPara: await translateText(
  //       "When you purchase the Locaradar you have to fill the User’s Information, which should contain your personal information as email, which is processed and stored by us"
  //     ),
  //     eighteenSubForthPara: await translateText(
  //       "You also have to fill the Payment Information, which should contain your Personal data, which is partly processed by us and processed by the payment providers."
  //     ),
  //     sixteenHeading: await translateText("MISCELLANEOUS"),
  //     nineteenPara: await translateText(
  //       "We may modify this Privacy Notice at any time and post any changes to the Privacy Notice on the Website, so please review it frequently. We indicate the date of the current version of this Privacy Notice below, so you know when it was last updated."
  //     ),
  //     nineteenSubFirstPara: await translateText(
  //       "Changes to this Privacy Notice may not affect your personal data we have previously collected from you or after such changes."
  //     ),
  //     nineteenSubSecondPara: await translateText(
  //       "If you object to the changes, please contact us as provided below."
  //     ),
  //     nineteenSubThirdPara: await translateText(
  //       "This Privacy Notice is governed by and construed with the laws of Cyprus."
  //     ),
  //     nineteenSubForthPara: await translateText(
  //       "The Cyprus courts shall have exclusive jurisdiction to settle any claim or dispute which might arise out of or in connection with this Privacy Notice."
  //     ),
  //     nineteenSubFifthPara: await translateText(
  //       "If you have any questions or propositions, please, get in touch with us by the e-mail."
  //     ),
  //   };
  //   setTranslatedText(translated);
  // };


  const translatePageContent = async () => {
    const translations = await Promise.all(
      Object.keys(defaultText).map(async key => ({
        [key]: await translateText(defaultText[key])
      }))
    );

    const translated = Object.assign({}, ...translations);
    setTranslatedText(translated);
  };
  useEffect(() => {
    translatePageContent();
  }, [language]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto",
    });
  }, []);

  return (
    <>
      <Navbar />

      <div className="container-fluid cookie">
        <div className="container">
          <h1>{translateText.firstHeading}</h1>
          <p>
            <b>{translatedText.firstPara}</b>
          </p>
          <p>{translatedText.secondPara}</p>
          <p>{translatedText.thirdPara}</p>
          <p>{translatedText.forthPara}</p>
          <p>
            {translatedText.fifthPara}
            <br />
            {translatedText.fifthSubPara}
          </p>
          <h4>{translatedText.secondHeading}</h4>
          <p>{translatedText.sixthPara}</p>
          <p>
            {translatedText.seventhPara} <br /> {translatedText.seventhSubPara}
          </p>
          <div className="row">
            <div className="col-lg-6 p-3">
              <h4>{translatedText.thirdHeading}</h4>
              <p>
                <b>{translatedText.eightHeadingPara}</b>{" "}
                {translatedText.eightPara}
              </p>
              <p>
                <b>{translatedText.ninthHeadingPara}</b>{" "}
                {translatedText.ninthPara}
              </p>
              <p>
                <b>{translatedText.tenthHeadingPara}</b>{" "}
                {translatedText.tenthPara}
              </p>
              <p>
                <b>{translatedText.eleventhHeadingPara}</b>{" "}
                {translatedText.eleventhPara}
              </p>
              <p>
                <b>{translatedText.twelethHeadingPara}</b>{" "}
                {translatedText.twelethPara}
              </p>
              <p>
                <b>{translatedText.thirteenHeadingPara}</b>{" "}
                {translatedText.thirteenPara}
              </p>
            </div>
            <div className="col-lg-6 p-3">
              <h4>{translatedText.forteenHeading}</h4>
              <p>
                {translatedText.forteenPara} <br />
                {translatedText.forteenSubPara}
                <ul>
                  <li>{translatedText.firstList}</li>
                  <li>{translatedText.secondList}</li>
                  <li>{translatedText.thirdList}</li>
                  <li>{translatedText.forthList}</li>
                </ul>
              </p>
              <p>{translatedText.fifteenPara}</p>
              <p>
                {translatedText.sixteenPara}
                <br />
                {translatedText.sixteenSubPara}
              </p>
            </div>
          </div>
          <h4>{translatedText.fifteenHeading}</h4>
          <p>{translatedText.seventeenPara}</p>
          <div className="cookie-table">
            <table>
              <thead>
                <tr>
                  <th>{translatedText.tableFirstHeading}</th>
                  <th>{translatedText.tableSeconndHeading}</th>
                  <th>{translatedText.tableThirdHeading}</th>
                  <th>{translatedText.tableForthHeading}</th>
                  <th>{translatedText.tableFifthHeading}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{translatedText.firstRowFruid}</td>
                  <td>{translatedText.firstRowParty}</td>
                  <td>{translatedText.firstRowSite}</td>
                  <td>{translatedText.firstRowCookie}</td>
                  <td>{translatedText.firstRowSession}</td>
                </tr>
                <tr>
                  <td>{translatedText.firstRowFruid}</td>
                  <td>{translatedText.firstRowParty}</td>
                  <td>{translatedText.firstRowSite}</td>
                  <td>{translatedText.firstRowCookie}</td>
                  <td>{translatedText.firstRowSession}</td>
                </tr>
                <tr>
                  <td>{translatedText.firstRowFruid}</td>
                  <td>{translatedText.firstRowParty}</td>
                  <td>{translatedText.firstRowSite}</td>
                  <td>{translatedText.firstRowCookie}</td>
                  <td>{translatedText.firstRowSession}</td>
                </tr>
                <tr>
                  <td>{translatedText.firstRowFruid}</td>
                  <td>{translatedText.firstRowParty}</td>
                  <td>{translatedText.firstRowSite}</td>
                  <td>{translatedText.firstRowCookie}</td>
                  <td>{translatedText.firstRowSession}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <p>
            {translatedText.eighteenPara}
            <br />
            {translatedText.eighteenSubFirstPara}
            <br />
            {translatedText.eighteenSubSecondPara}
            <br />
            {translatedText.eighteenSubThirdPara}
            <br />
            {translatedText.eighteenSubForthPara}
          </p>
          <h4>{translatedText.sixteenHeading}</h4>
          <p>
            {translatedText.nineteenPara}
            <br />
            {translatedText.nineteenSubFirstPara}
            <br />
            {translatedText.nineteenSubSecondPara}
            <br />
            {translatedText.nineteenSubThirdPara}
            <br />
            {translatedText.nineteenSubForthPara}
            <br />
            {translatedText.nineteenSubFifthPara}
          </p>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Privacy;
