import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axiosClient from "../axios-client";
import iziToast from "izitoast";
import { useTranslation } from "../TranslationContext";

const Footer = () => {

  const { translateText, language } = useTranslation();
  const [translatedText, setTranslatedText] = useState({});
  const defaultText = {
    joinNewsLetter: "Join Our Newsletter to Keep Up To Date With Us",
    enterEmail: "Enter Your Email",
    subscribe: "Subscribe",
    company: "Company",
    contact: "Contact",
    cookiePolicy: "Cookie Policy",
    help: "Help",
    terms: "Terms & Conditions",
    privacyPolicy: "Privacy Policy",
    reservedRights: "All rights reserved.",
  
  };

  // const translatePageContent = async () => {
  //   const translated = {
   
  //     joinNewsLetter: await translateText("Join Our Newsletter to Keep Up To Date With Us"),
  //     enterEmail: await translateText("Enter Your Email"),
  //     subscribe: await translateText("Subscribe"),
  //     company: await translateText("Company"),
  //     contact: await translateText("Contact"),
  //     cookiePolicy: await translateText("Cookie Policy"),
  //     help: await translateText("Help"),
  //     terms: await translateText("Terms & Conditions"),
  //     privacyPolicy: await translateText("Privacy Policy"),
  //     reservedRights: await translateText("All rights reserved."),

  //   };
  //   setTranslatedText(translated);
  // };


  const translatePageContent = async () => {
    const translations = await Promise.all(
      Object.keys(defaultText).map(async key => ({
        [key]: await translateText(defaultText[key])
      }))
    );

    const translated = Object.assign({}, ...translations);
    setTranslatedText(translated);
  };

  useEffect(() => {
    translatePageContent();
  }, [language]);

  const [email, setEmail] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const { data } = await axiosClient.post("/subscribers", { email });

      if (data.status == "true") {
        iziToast.success({
          message: data.message,
          position: "topCenter",
        });
      } else {
        iziToast.error({
          message: data.message,
          position: "topCenter",
        });
      }
    } catch (err) {
      iziToast.error({
        message: err,
        position: "topCenter",
      });
      console.error(err);
    }
  };
  return (
    <>
      <div className="container-fluid footer-top">
        <div className="container">
          <div className="row f-row">
            <div className="col-lg-4">
              <h3>{translatedText.joinNewsLetter}!</h3>
            </div>
            <div className="col-lg-6">
              <form action="" className="row">
                <div className="col-lg-8">
                  <input
                    type="email"
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder={translatedText.enterEmail}
                  />
                </div>
                <div className="col-lg-4">
                  <button onClick={handleSubmit}>{translatedText.subscribe}</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid footer">
        <div className="container">
          <div className="row footer-row mb-4">
            <div className="col-lg-6 footer-col">
              <div className="col-lg-8">
                <div className="footer-logo">
                  <img src="images-local/logo.png" alt="" />
                </div>
                <p>
                  Address: İstanbul beşiktaş ıhlamurdere caddesi mutluer
                  apartmanı no 82 iç kapı no 2 Phone: +90 545 674 02 42
                </p>
                <div className="footer-social">
                  <i className="bi bi-facebook"></i>
                  <i className="bi bi-twitter"></i>
                  <i className="bi bi-instagram"></i>
                </div>
              </div>
            </div>
            <div className="col-lg-6 row">
              <div className="col-lg-4 footer-col">
                <h3>{translatedText.company}</h3>
                <div>
                  <Link to="/contact" className="text-decoration-none">
                    <li>{translatedText.contact}</li>
                  </Link>
                  <Link to="/cookie-policy" className="text-decoration-none">
                    <li>{translatedText.cookiePolicy}</li>
                  </Link>
                </div>
              </div>

              <div className="col-lg-4 footer-col">
                <h3>{translatedText.help}</h3>
                <div>
                  <Link to="/tnc" className="text-decoration-none">
                    <li>{translatedText.terms}</li>
                  </Link>
                  <Link to="/privacy-policy" className="text-decoration-none">
                    <li>{translatedText.privacyPolicy}</li>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-bottom">
            <p>
              © 2024 <b>LOCARADAR</b>. {translatedText.reservedRights}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
