import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useStateContext } from "../contexts/AuthContextProvider";
import axiosClient from "../axios-client";
import iziToast from "izitoast";
import { useTranslation } from "../TranslationContext";

const Payment = () => {
  const { translateText, language } = useTranslation();
  const [translatedText, setTranslatedText] = useState({});
  const defaultText = {
    successMessage: "Payment Successful",
    paymentInfo: "Add Payment Info",
    firstField: "Name",
    enterfirstField: "Enter Full Name",
    secondField: "Email",
    entersecondField: "Enter Email",
    thirdField: "Phone Number",
    forthField: "Address",
    enterforthField: "Enter Address",
    planType: "Your Plan",
    price: "Price",
    continue: "Continue Payment",
};

  // const translatePageContent = async () => {
  //   const translated = {
  //     successMessage: await translateText("Payment Successful"),
  //     paymentInfo: await translateText("Add Payment Info"),
  //     firstField: await translateText("Name"),
  //     enterfirstField: await translateText("Enter Full Name"),
  //     secondField: await translateText("Email"),
  //     entersecondField: await translateText("Enter Email"),
  //     thirdField: await translateText("Phone Number"),
  //     forthField: await translateText("Address"),
  //     enterforthField: await translateText("Enter Address"),
  //     planType: await translateText("Your Plan"),
  //     price: await translateText("Price"),
  //     continue: await translateText("Continue Payment"),
  //   };
  //   setTranslatedText(translated);
  // };

  const translatePageContent = async () => {
    const translations = await Promise.all(
      Object.keys(defaultText).map(async key => ({
        [key]: await translateText(defaultText[key])
      }))
    );

    const translated = Object.assign({}, ...translations);
    setTranslatedText(translated);
  };
  
  
  
  useEffect(() => {
    translatePageContent();
  }, [language]);

  const [trialPrice, setTrialPrice] = useState(null);
  const [trialPeriod, setTrialPeriod] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const { apipath, token, user } = useStateContext();
  const navigate = useNavigate();
  const params = useLocation();
  const searchParams = new URLSearchParams(params.search);
  const status = searchParams.get("status");
  const merchant_oid = searchParams.get("merchant_oid");
  const total_amount = searchParams.get("total_amount");
  const paytr_token = searchParams.get("paytr_token");

  const [userData, setUserData] = useState({
    name: "",
    email: "",
    phone: "",
    city: "",
    state: "",
    country: "",
    address: "",
    created_at: "",
  });

  const checkPayment = async () => {
    if (status === "success") {
      iziToast.success({
        message: translatedText.successMessage,
        position: "topCenter",
      });
      try {
        const { data } = await axiosClient.post("/payment/callback", {
          status,
          merchant_oid,
          total_amount,
          paytr_token,
        });
      } catch (err) {
        console.error(err);
      }
    }
  };

  useEffect(() => {
    checkPayment();
  }, []);

  const fetchPricing = async () => {
    try {
      const { data } = await axiosClient.get("/prices-details");
      setTrialPrice(data.price);
      setTrialPeriod(data.time_period);
      setPaymentMethod(data.payment_method);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchPricing();
  }, []);

  const handleChange = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    // e.preventDefault();
    try {
      // const { data } = await axiosClient.post("/intial-payment", userData, {
      //   headers: {
      //     Authorization: `Bearer ${token}`,
      //   },
      // });
      // console.log(data);
      // if (data.result.status === "success") {
      //   //window.location.href = `https://www.paytr.com/odeme/guvenli/${data.result.token}`;

      // } else {
      //   iziToast.error({
      //     message: data.message,
      //     position: "topCenter",
      //   });
      // }

      let apiUrl = "";

      if (paymentMethod === "paytr") {
        apiUrl = "/intial-payment";
      } else if (paymentMethod === "stripe") {
        apiUrl = "/stripe";
      }

      const { data } = await axiosClient.post(apiUrl, userData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      console.log(data);
      if (data.result && data.result.status === "success") {
        if (paymentMethod === "paytr") {
          window.location.href = `https://www.paytr.com/odeme/guvenli/${data.result.token}`;
        } else if (paymentMethod === "stripe") {
          window.location.href = `https://stripe.com/checkout/${data.result.token}`;
        }
      } else {
        iziToast.error({
          message: data.message,
          position: "topCenter",
        });
      }
    } catch (err) {
      console.error(err);
    }
  };
  return (
    <>
      <Navbar />
      <Link to="/" className="float">
        <img src="images-local/help.png" alt="" />
      </Link>
      <div className="container-fluid location">
        <div className="container login">
          <div className="row">
            <div className="col-lg-6 login-left">
              <div className="l-logo">
                <img src="images-local/logo2.png" alt="" />
              </div>
              <h1>{translatedText.paymentInfo}</h1>

              <div className="login-form row">
                <div className="col-lg-6">
                  <div className="login-item pay-item">
                    <label htmlFor="">{translatedText.firstField}</label>
                    <input
                      required
                      type="text"
                      name="name"
                      onChange={handleChange}
                      id="name"
                      placeholder={translatedText.enterfirstField}
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="login-item  pay-item">
                    <label htmlFor="">{translatedText.secondField}</label>
                    <input
                      type="email"
                      required
                      onChange={handleChange}
                      name="email"
                      id="email"
                      placeholder={translatedText.entersecondField}
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="login-item  pay-item">
                    <label htmlFor="">{translatedText.thirdField}</label>
                    <input
                      type="number"
                      required
                      onChange={handleChange}
                      name="phone"
                      id="phone"
                      placeholder="0000 000 000"
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="login-item  pay-item">
                    <label htmlFor="">{translatedText.forthField}</label>
                    <input
                      type="text"
                      required
                      onChange={handleChange}
                      name="address"
                      id="address"
                      placeholder={translatedText.enterforthField}
                    />
                  </div>
                </div>
                <div className="col-lg-12 mt-4">
                  <div className="loc-text">
                    <span>{translatedText.planType}</span>
                    <span>{trialPeriod ? trialPeriod : "Loading..."}</span>
                  </div>
                  <div className="loc-text">
                    <span>{translatedText.price}</span>
                    <span>TL {trialPrice ? trialPrice : "Loading..."}</span>
                  </div>
                </div>
                <div className="login-btn">
                  <button onClick={() => handleSubmit()}>
                    {translatedText.continue}
                  </button>
                </div>
              </div>
            </div>
            <div className="col-lg-6 login-img">
              <img src="images-local/phone.png" alt="" />
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Payment;
