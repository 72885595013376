import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useStateContext } from "../contexts/AuthContextProvider";
import { useTranslation } from "../TranslationContext";

const Location = () => {
  const { translateText, language } = useTranslation();
  const [translatedText, setTranslatedText] = useState({});
  const defaultText = {
    mainHeading: "Finding the Location ...",
    firstField: "Carrier",
    secondField: "Country",
    thirdField: "Time Zone",
    forthField: "City",
    fifthField: "Facebook",
    sixthField: "Tinder",
  };

  // const translatePageContent = async () => {
  //   const translated = {
  //     mainHeading: await translateText("Finding the Location"),
  //     firstField: await translateText("Carrier"),
  //     secondField: await translateText("Country"),
  //     thirdField: await translateText("Time Zone"),
  //     forthField: await translateText("City"),
  //     fifthField: await translateText("Instagram"),
  //     sixthField: await translateText("Facebook"),
  //     seventhField: await translateText("Tinder"),
  //   };
  //   setTranslatedText(translated);
  // };

  const translatePageContent = async () => {
    const translations = await Promise.all(
      Object.keys(defaultText).map(async key => ({
        [key]: await translateText(defaultText[key])
      }))
    );

    const translated = Object.assign({}, ...translations);
    setTranslatedText(translated);
  };
  
  useEffect(() => {
    translatePageContent();
  }, [language]);

  const { user } = useStateContext();
  const navigate = useNavigate();
  const location = useLocation();
  const { code, phone, details } = location.state || {};
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 10000);

    return () => clearTimeout(timer);
  }, []);
  const [userLocation, setUserLocation] = useState({
    country: "",
    city: "",
    full_address: "",
    longitude: "",
    latitude: "",
    timezone: "",
  });

  useEffect(() => {
    if (details) {
      setUserLocation({
        country: details.country || "Unknown",
        city: details.city || "Unknown",
        timezone: details.timezone || "Unknown",
      });
      const timer = setTimeout(() => {
        // navigate("/plan", { state: { phone, code } });

        if (user) {
          navigate("/location-detail");
        } else {
          navigate("/plan", { state: { phone, code } });
        }
      }, 5000);

      return () => clearTimeout(timer);
    } else {
      navigate("/");
    }
  }, [details]);

  return (
    <>
      <Navbar />
      <Link to="/" className="float">
        <img src="images-local/help.png" alt="" />
      </Link>
      <div className="container-fluid location">
        <div className="container">
          <div className="col-lg-8 mx-auto location-1">
            <div className="col-lg-10 mx-auto location-2">
              <div className="col-lg-10 mx-auto location-3">
                <div className="loc-icon">
                  <img src="images-local/location-1.png" alt="" />
                </div>
                <h6>
                  {" "}
                  <div className="spinner"></div>
                  {translateText.mainHeading} ...
                </h6>
                <h2>
                  {code}-{phone}
                </h2>
                <div className="loc-text">
                  <span>{translatedText.firstField}</span>
                  <span>{details?.carrier}</span>
                </div>
                <div className="loc-text">
                  <span>{translatedText.secondField}</span>
                  <span>{userLocation.country}</span>
                </div>
                <div className="loc-text">
                  <span>{translatedText.thirdField}</span>
                  <span className="blur">IST(UTC + 5:30)</span>
                </div>
                <div className="loc-text">
                  <span>{translatedText.forthField}</span>
                  <span className="blur">Turkey</span>
                </div>

                <div className="loc-text">
                  <span>{translatedText.fifthField}</span>
                  <span className="blur">@John Doe</span>
                </div>

                <div className="loc-text">
                  <span>{translatedText.sixthField}</span>
                  <span className="blur">@JohnDoe112</span>
                </div>

                <div className="loc-text">
                  <span>{translatedText.seventhField}</span>
                  <span className="blur">@12JohnDoe</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Location;
