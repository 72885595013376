import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { useTranslation } from "../TranslationContext";

const Cookie = () => {
  const { translateText, language } = useTranslation();
  const [translatedText, setTranslatedText] = useState({});
  const defaultText = {
    firstHeading: "Cookie Policy",
    firstPara:
      "This Cookie Policy explains how we use cookies and other similar technologies when you use our service, what tracking technologies are and why we use them. It also explains how you can control their use.",
    secondHeading: "COOKIES",

    thirdPara:
      "A cookie is a small text file which is placed onto your device (e.g. computer, smartphone or other electronic device) when you visit our website to store a range of information, for example, your language preference, or browser and device you are using to view the website. Those cookies are set by us and called first-party cookies. We also use third-party cookies, which are cookies from a domain different from the domain of our website, for our advertising and marketing efforts.",

    forthPara:
      "Session cookies expire each time you close your browser and do not remain on your device afterwards. These cookies allow our website to link your actions during a particular browser session.",
    forthSubPara:
      "Persistent cookies will remain on your device for a period of time, and will expire on a set expiration date, or when you delete them manually from your cache. These cookies are stored on your device in between browser sessions and allow your preferences and actions across our website to be remembered..",

    thirdHeading: "SIMILAR TECHNOLOGIES",

    eightPara:
      "In addition to cookies, there are other similar technologies that may be used on the web or in mobile ecosystems.",

    firstList:
      "Web beacon is a small, transparent image (also known as “pixel tag”, “web bug”, “GIF”) that contains a unique identifier. It is embedded into a website or an email. When your browser reads the code of the website it communicates with our server in order to display the image and, through this process, we are able to acquire information such as technical specifications of your device, operating systems and settings being used. It can also track your activities during a session. Also, it enables us to recognize when each email was opened, from what IP address and from what device. We use this information to improve our email communication.",
    secondList:
      "Software development kits (SDK) are third-party software development kits that may be installed in our mobile applications. SDKs help us understand how you interact with our mobile applications and collect certain information about the device and network you use to access the application.",
    thirdList:
      "Local shared objects, commonly called “Flash cookies”, are pieces of data that websites which use Adobe Flash may store on a user's computer to facilitate the Flash functionality. We may use Adobe Flash to display graphics, interactive animations and other enhanced functionality. Local shared objects can track similar parameters to cookies, but they can also provide information on your use of the specific feature that the cookie is enabling. For example, if it facilitates the display of a video then we can receive details of how much of the video was watched and when viewing stopped. Flash cookie management tools can be accessed directly via www.adobe.com.",
    forthList:
      "HTML5 local storage. HTML 5 is the fifth version of the HTML language and contains functionality that allows information to be stored locally within the browser’s data files. HTML5 local storage operates in a similar way to cookies but differs in that it can hold greater amounts of information and does not rely on an exchange of data with the website’s server.",
    fifthList:
      "Fingerprinting is a technique that involves combining a set ofinformation elements in order to uniquely identify a particular device. These information elements include, for example: data from configuration of the device, CSS information, JavaScript objects, installed fonts, installed plugins with the browser, use of any APIs, HTTP header information, clock information.",
    sixthList:
      "Device Identifiers are identifiers comprised of numbers and letters, which are unique to each specific device. These include Apple’s ID for Advertisers (IDFA) and Google’s Android Advertising ID (AAID). They are stored on the device and are used to recognize you and your devices across different apps and devices for marketing and advertising purposes. You can reset your device identifier or opt out of personalized advertising in the settings of your device.",
    seventhList:
      "Social widgets are buttons or icons provided by third-party social media providers that allow you to interact with those social media services when you view a web page or a mobile app screen. These social widgets may collect browsing data, which may be received by the third party that provided the widget, and are controlled by the third parties.",
    forthHeading: "HOW CAN YOU MANAGE YOUR COOKIES AND SIMILAR TECHNOLOGIES?",
    forthSubHeading: "Browser and device settings",
    fifthPara:
      "Most browsers allow you to refuse to accept cookies and to delete cookies. The methods for doing so vary from browser to browser, and from version to version. You can however obtain up-to-date information about blocking and deleting cookies via these links:",
    unorderedFirstList: "Chrome",
    unorderedSecondList: "Firefox",
    unorderedThirdList: "Opera",
    unorderedForthList: "Internet Explorer",
    sixthPara:
      "Detailed instructions on how to control your cookies through browser settings can also be found here:",
    sixthSubFirstPara:
      "Please note that blocking all cookies will have a negative impact on the usability of many websites. If you block cookies, you will not be able to use all the features on our website. To reset your device identifier or opt-out of personalized advertising, follow Google instructions or Apple instructions.",
    sixthSubSecondPara:
      "To opt out of data usage by Google Analytics follow instructions:",
    fifthHeading: "Opt-out of internet-based advertising",
    seventhPara:
      "The third-party advertisers, ad agencies and vendors with which we work may be members of the Network Advertising Initiative, the Digital Advertising Alliance Self-Regulatory Program for Online Behavioural Advertising, the European Digital Advertising Alliance. To opt-out of interest-based advertising from the participating companies, visit the following links:",
    unorderedFifthList: "Network Advertising Initiative",
    sixthHeading: "COOKIES LIST",
    eightPara:
      "You can find more information about the individual cookies we use and the purposes for which we use them in the table below:",
    ninthHeadingPara: "Necessary cookies :",
    ninthPara:
      "These cookies are necessary for the website to function and cannot be switched off in our systems. They are usually only set in response to actions made by you which amount to a request for services, such as setting your privacy preferences, logging in or filling in forms. You can set your browser to block or alert you about these cookies, but some parts of the site will not then work.",
    tableFirstHeading: "Cookie",
    tableSeconndHeading: "Type",
    tableThirdHeading: "Provider",
    tableForthHeading: "Purpose",
    tableFifthHeading: "Expiry",
    firstRowFruid: "__cfruid",
    firstRowParty: "3d party",
    firstRowSite: "cloudflare.com",
    firstRowCookie:
      "This cookie is used by service Cloud Flare and required to manage incoming traffic and to have better visibility on the origin of a particular request",
    firstRowSession: "Session",
    tenthPara: "Functional cookies : ",
    tenthSubPara:
      "These cookies are necessary for the website to function and cannot be switched off in our systems. They are usually only set in response to actions made by you which amount to a request for services, such as setting your privacy preferences, logging in or filling in forms. You can set your browser to block or alert you about these cookies, but some parts of the site will not then work.",
    eleventhPara: "Analytical or performance cookies :",
    eleventhSubPara:
      "These cookies are necessary for the website to function and cannot be switched off in our systems. They are usually only set in response to actions made by you which amount to a request for services, such as setting your privacy preferences, logging in or filling in forms. You can set your browser to block or alert you about these cookies, but some parts of the site will not then work.",
    twelethPara: "CHANGES TO THIS POLICY",
    twelethSubPara:
      "We may change this policy from time to time, when we do, we will inform you by updating the “Last updated” date below.",
    thirthteenPara: "Last updated:",
  };

  // const translatePageContent = async () => {
  //   const translated = {
  //     firstHeading: await translateText("Cookie Policy"),
  //     firstPara: await translateText(
  //       "This Cookie Policy explains how we use cookies and other similar technologies when you use our service, what tracking technologies are and why we use them. It also explains how you can control their use."
  //     ),
  //     secondHeading: await translateText("COOKIES"),

  //     thirdPara: await translateText(
  //       "A cookie is a small text file which is placed onto your device (e.g. computer, smartphone or other electronic device) when you visit our website to store a range of information, for example, your language preference, or browser and device you are using to view the website. Those cookies are set by us and called first-party cookies. We also use third-party cookies, which are cookies from a domain different from the domain of our website, for our advertising and marketing efforts."
  //     ),

  //     forthPara: await translateText(
  //       "Session cookies expire each time you close your browser and do not remain on your device afterwards. These cookies allow our website to link your actions during a particular browser session."
  //     ),
  //     forthSubPara: await translateText(
  //       "Persistent cookies will remain on your device for a period of time, and will expire on a set expiration date, or when you delete them manually from your cache. These cookies are stored on your device in between browser sessions and allow your preferences and actions across our website to be remembered.."
  //     ),

  //     thirdHeading: await translateText("SIMILAR TECHNOLOGIES"),

  //     eightPara: await translateText(
  //       "In addition to cookies, there are other similar technologies that may be used on the web or in mobile ecosystems."
  //     ),

  //     firstList: await translateText(
  //       "Web beacon is a small, transparent image (also known as “pixel tag”, “web bug”, “GIF”) that contains a unique identifier. It is embedded into a website or an email. When your browser reads the code of the website it communicates with our server in order to display the image and, through this process, we are able to acquire information such as technical specifications of your device, operating systems and settings being used. It can also track your activities during a session. Also, it enables us to recognize when each email was opened, from what IP address and from what device. We use this information to improve our email communication."
  //     ),
  //     secondList: await translateText(
  //       "Software development kits (SDK) are third-party software development kits that may be installed in our mobile applications. SDKs help us understand how you interact with our mobile applications and collect certain information about the device and network you use to access the application."
  //     ),
  //     thirdList: await translateText(
  //       "Local shared objects, commonly called “Flash cookies”, are pieces of data that websites which use Adobe Flash may store on a user's computer to facilitate the Flash functionality. We may use Adobe Flash to display graphics, interactive animations and other enhanced functionality. Local shared objects can track similar parameters to cookies, but they can also provide information on your use of the specific feature that the cookie is enabling. For example, if it facilitates the display of a video then we can receive details of how much of the video was watched and when viewing stopped. Flash cookie management tools can be accessed directly via www.adobe.com."
  //     ),
  //     forthList: await translateText(
  //       "HTML5 local storage. HTML 5 is the fifth version of the HTML language and contains functionality that allows information to be stored locally within the browser’s data files. HTML5 local storage operates in a similar way to cookies but differs in that it can hold greater amounts of information and does not rely on an exchange of data with the website’s server."
  //     ),
  //     fifthList: await translateText(
  //       "Fingerprinting is a technique that involves combining a set ofinformation elements in order to uniquely identify a particular device. These information elements include, for example: data from configuration of the device, CSS information, JavaScript objects, installed fonts, installed plugins with the browser, use of any APIs, HTTP header information, clock information."
  //     ),
  //     sixthList: await translateText(
  //       "Device Identifiers are identifiers comprised of numbers and letters, which are unique to each specific device. These include Apple’s ID for Advertisers (IDFA) and Google’s Android Advertising ID (AAID). They are stored on the device and are used to recognize you and your devices across different apps and devices for marketing and advertising purposes. You can reset your device identifier or opt out of personalized advertising in the settings of your device."
  //     ),
  //     seventhList: await translateText(
  //       "Social widgets are buttons or icons provided by third-party social media providers that allow you to interact with those social media services when you view a web page or a mobile app screen. These social widgets may collect browsing data, which may be received by the third party that provided the widget, and are controlled by the third parties."
  //     ),
  //     forthHeading: await translateText(
  //       "HOW CAN YOU MANAGE YOUR COOKIES AND SIMILAR TECHNOLOGIES?"
  //     ),
  //     forthSubHeading: await translateText("Browser and device settings"),
  //     fifthPara: await translateText(
  //       "Most browsers allow you to refuse to accept cookies and to delete cookies. The methods for doing so vary from browser to browser, and from version to version. You can however obtain up-to-date information about blocking and deleting cookies via these links:"
  //     ),
  //     unorderedFirstList: await translateText("Chrome"),
  //     unorderedSecondList: await translateText("Firefox"),
  //     unorderedThirdList: await translateText("Opera"),
  //     unorderedForthList: await translateText("Internet Explorer"),
  //     sixthPara: await translateText(
  //       "Detailed instructions on how to control your cookies through browser settings can also be found here:"
  //     ),
  //     sixthSubFirstPara: await translateText(
  //       "Please note that blocking all cookies will have a negative impact on the usability of many websites. If you block cookies, you will not be able to use all the features on our website. To reset your device identifier or opt-out of personalized advertising, follow Google instructions or Apple instructions."
  //     ),
  //     sixthSubSecondPara: await translateText(
  //       "To opt out of data usage by Google Analytics follow instructions:"
  //     ),
  //     fifthHeading: await translateText(
  //       "Opt-out of internet-based advertising"
  //     ),
  //     seventhPara: await translateText(
  //       "The third-party advertisers, ad agencies and vendors with which we work may be members of the Network Advertising Initiative, the Digital Advertising Alliance Self-Regulatory Program for Online Behavioural Advertising, the European Digital Advertising Alliance. To opt-out of interest-based advertising from the participating companies, visit the following links:"
  //     ),
  //     unorderedFifthList: await translateText("Network Advertising Initiative"),
  //     sixthHeading: await translateText("COOKIES LIST"),
  //     eightPara: await translateText(
  //       "You can find more information about the individual cookies we use and the purposes for which we use them in the table below:"
  //     ),
  //     ninthHeadingPara: await translateText("Necessary cookies :"),
  //     ninthPara: await translateText(
  //       "These cookies are necessary for the website to function and cannot be switched off in our systems. They are usually only set in response to actions made by you which amount to a request for services, such as setting your privacy preferences, logging in or filling in forms. You can set your browser to block or alert you about these cookies, but some parts of the site will not then work."
  //     ),
  //     tableFirstHeading: await translateText("Cookie"),
  //     tableSeconndHeading: await translateText("Type"),
  //     tableThirdHeading: await translateText("Provider"),
  //     tableForthHeading: await translateText("Purpose"),
  //     tableFifthHeading: await translateText("Expiry"),
  //     firstRowFruid: await translateText("__cfruid"),
  //     firstRowParty: await translateText("3d party"),
  //     firstRowSite: await translateText("cloudflare.com"),
  //     firstRowCookie: await translateText(
  //       "This cookie is used by service Cloud Flare and required to manage incoming traffic and to have better visibility on the origin of a particular request"
  //     ),
  //     firstRowSession: await translateText("Session"),
  //     tenthPara: await translateText("Functional cookies : "),
  //     tenthSubPara: await translateText(
  //       "These cookies are necessary for the website to function and cannot be switched off in our systems. They are usually only set in response to actions made by you which amount to a request for services, such as setting your privacy preferences, logging in or filling in forms. You can set your browser to block or alert you about these cookies, but some parts of the site will not then work."
  //     ),
  //     eleventhPara: await translateText("Analytical or performance cookies :"),
  //     eleventhSubPara: await translateText(
  //       "These cookies are necessary for the website to function and cannot be switched off in our systems. They are usually only set in response to actions made by you which amount to a request for services, such as setting your privacy preferences, logging in or filling in forms. You can set your browser to block or alert you about these cookies, but some parts of the site will not then work."
  //     ),
  //     twelethPara: await translateText("CHANGES TO THIS POLICY"),
  //     twelethSubPara: await translateText(
  //       "We may change this policy from time to time, when we do, we will inform you by updating the “Last updated” date below."
  //     ),
  //     thirthteenPara: await translateText("Last updated:"),
  //   };
  //   setTranslatedText(translated);
  // };

  const translatePageContent = async () => {
    const translations = await Promise.all(
      Object.keys(defaultText).map(async key => ({
        [key]: await translateText(defaultText[key])
      }))
    );

    const translated = Object.assign({}, ...translations);
    setTranslatedText(translated);
  };
  
  useEffect(() => {
    translatePageContent();
  }, [language]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto",
    });
  }, []);

  return (
    <>
      <Navbar />

      <div className="container-fluid cookie">
        <div className="container">
          <h1>{translateText.firstHeading}</h1>
          <p>{translateText.firstPara}</p>
          <h4>{translateText.secondHeading}</h4>
          <p>{translatedText.thirdPara}</p>
          <p>
            {translatedText.forthPara} <br /> {translatedText.forthSubPara}
          </p>
          <h4>{translatedText.thirdHeading}</h4>
          <p>{translatedText.eightPara}</p>
          <ul>
            <li>{translatedText.firstList}</li>
            <li>{translatedText.secondList}</li>
            <li>{translatedText.thirdList}</li>
            <li>{translatedText.forthList}</li>
            <li>{translatedText.fifthList}</li>
            <li>{translatedText.sixthList}</li>
            <li>{translatedText.seventhList}</li>
          </ul>
          <h4>{translatedText.forthHeading}</h4>
          <ol>
            <li>
              <b> {translatedText.forthSubHeading}s</b>{" "}
              <p>{translatedText.fifthPara}</p>
              <ul>
                <li>{translatedText.unorderedFirstList}</li>
                <li>{translatedText.unorderedSecondList}</li>
                <li>{translatedText.unorderedThirdList}</li>
                <li>{translatedText.unorderedForthList}</li>
              </ul>
              <p>
                {translatedText.sixthPara}{" "}
                <a href="https://www.aboutcookies.org/how-to-control-cookies/">
                  https://www.aboutcookies.org/how-to-control-cookies/
                </a>
                <br />
                {translatedText.sixthSubFirstPara}
                <br />
                {translatedText.sixthSubSecondPara}{" "}
                <a href="https://tools.google.com/dlpage/gaoptout">
                  https://tools.google.com/dlpage/gaoptout
                </a>
                .
              </p>
            </li>
            <li>
              <b>{translatedText.fifthHeading}</b>{" "}
              <p>{translatedText.seventhPara}</p>
              <ul>
                <li>
                  {translatedText.unorderedFifthList}{" "}
                  <a href=" http://optout.networkadvertising.org/">
                    {" "}
                    http://optout.networkadvertising.org/
                  </a>
                </li>
                <li>
                  {translatedText.unorderedFifthList}{" "}
                  <a href=" http://optout.networkadvertising.org/">
                    {" "}
                    http://optout.networkadvertising.org/
                  </a>
                </li>
                <li>
                  {translatedText.unorderedFifthList}{" "}
                  <a href=" http://optout.networkadvertising.org/">
                    {" "}
                    http://optout.networkadvertising.org/
                  </a>
                </li>
                <li>
                  {translatedText.unorderedFifthList}{" "}
                  <a href=" http://optout.networkadvertising.org/">
                    {" "}
                    http://optout.networkadvertising.org/
                  </a>
                </li>
              </ul>
            </li>
          </ol>
          <h4>{translatedText.sixthHeading}</h4>
          <p>{translatedText.eightPara}</p>
          <ol>
            <li>
              <b>{translatedText.ninthHeadingPara} </b>{" "}
              {translatedText.ninthPara}
              <div className="cookie-table">
                <table>
                  <thead>
                    <tr>
                      <th>{translatedText.tableFirstHeading}</th>
                      <th>{translatedText.tableSeconndHeading}</th>
                      <th>{translatedText.tableThirdHeading}</th>
                      <th>{translatedText.tableForthHeading}</th>
                      <th>{translatedText.tableFifthHeading}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{translatedText.firstRowFruid}</td>
                      <td>{translatedText.firstRowParty}</td>
                      <td>{translatedText.firstRowSite}</td>
                      <td>{translatedText.firstRowCookie}</td>
                      <td>{translatedText.firstRowSession}</td>
                    </tr>
                    <tr>
                      <td>{translatedText.firstRowFruid}</td>
                      <td>{translatedText.firstRowParty}</td>
                      <td>{translatedText.firstRowSite}</td>
                      <td>{translatedText.firstRowCookie}</td>
                      <td>{translatedText.firstRowSession}</td>
                    </tr>
                    <tr>
                      <td>{translatedText.firstRowFruid}</td>
                      <td>{translatedText.firstRowParty}</td>
                      <td>{translatedText.firstRowSite}</td>
                      <td>{translatedText.firstRowCookie}</td>
                      <td>{translatedText.firstRowSession}</td>
                    </tr>
                    <tr>
                      <td>{translatedText.firstRowFruid}</td>
                      <td>{translatedText.firstRowParty}</td>
                      <td>{translatedText.firstRowSite}</td>
                      <td>{translatedText.firstRowCookie}</td>
                      <td>{translatedText.firstRowSession}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </li>
            <li>
              <b>{translatedText.tenthPara} </b> {translatedText.tenthSubPara}
              <div className="cookie-table">
                <table>
                  <thead>
                    <tr>
                      <th>{translatedText.tableFirstHeading}</th>
                      <th>{translatedText.tableSeconndHeading}</th>
                      <th>{translatedText.tableThirdHeading}</th>
                      <th>{translatedText.tableForthHeading}</th>
                      <th>{translatedText.tableFifthHeading}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{translatedText.firstRowFruid}</td>
                      <td>{translatedText.firstRowParty}</td>
                      <td>{translatedText.firstRowSite}</td>
                      <td>{translatedText.firstRowCookie}</td>
                      <td>{translatedText.firstRowSession}</td>
                    </tr>
                    <tr>
                      <td>{translatedText.firstRowFruid}</td>
                      <td>{translatedText.firstRowParty}</td>
                      <td>{translatedText.firstRowSite}</td>
                      <td>{translatedText.firstRowCookie}</td>
                      <td>{translatedText.firstRowSession}</td>
                    </tr>
                    <tr>
                      <td>{translatedText.firstRowFruid}</td>
                      <td>{translatedText.firstRowParty}</td>
                      <td>{translatedText.firstRowSite}</td>
                      <td>{translatedText.firstRowCookie}</td>
                      <td>{translatedText.firstRowSession}</td>
                    </tr>
                    <tr>
                      <td>{translatedText.firstRowFruid}</td>
                      <td>{translatedText.firstRowParty}</td>
                      <td>{translatedText.firstRowSite}</td>
                      <td>{translatedText.firstRowCookie}</td>
                      <td>{translatedText.firstRowSession}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </li>
            <li>
              <b>{translatedText.eleventhPara} </b>{" "}
              {translatedText.eleventhSubPara}
              <div className="cookie-table">
                <table>
                  <thead>
                    <tr>
                      <th>{translatedText.tableFirstHeading}</th>
                      <th>{translatedText.tableSeconndHeading}</th>
                      <th>{translatedText.tableThirdHeading}</th>
                      <th>{translatedText.tableForthHeading}</th>
                      <th>{translatedText.tableFifthHeading}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{translatedText.firstRowFruid}</td>
                      <td>{translatedText.firstRowParty}</td>
                      <td>{translatedText.firstRowSite}</td>
                      <td>{translatedText.firstRowCookie}</td>
                      <td>{translatedText.firstRowSession}</td>
                    </tr>
                    <tr>
                      <td>{translatedText.firstRowFruid}</td>
                      <td>{translatedText.firstRowParty}</td>
                      <td>{translatedText.firstRowSite}</td>
                      <td>{translatedText.firstRowCookie}</td>
                      <td>{translatedText.firstRowSession}</td>
                    </tr>
                    <tr>
                      <td>{translatedText.firstRowFruid}</td>
                      <td>{translatedText.firstRowParty}</td>
                      <td>{translatedText.firstRowSite}</td>
                      <td>{translatedText.firstRowCookie}</td>
                      <td>{translatedText.firstRowSession}</td>
                    </tr>
                    <tr>
                      <td>{translatedText.firstRowFruid}</td>
                      <td>{translatedText.firstRowParty}</td>
                      <td>{translatedText.firstRowSite}</td>
                      <td>{translatedText.firstRowCookie}</td>
                      <td>{translatedText.firstRowSession}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </li>
          </ol>
          <h4>{translatedText.twelethPara}</h4>
          <p>
            {translatedText.twelethSubPara}
            <br />
            <b>{translatedText.thirthteenPara} 24.01.2024</b>
          </p>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Cookie;
