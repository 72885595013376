import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Link, useNavigate } from "react-router-dom";

import { useStateContext } from "../contexts/AuthContextProvider";
import axiosClient from "../axios-client";
import iziToast from "izitoast";
import { useTranslation } from "../TranslationContext";

const Register = () => {
  const { translateText, language } = useTranslation();
  const [translatedText, setTranslatedText] = useState({});
  const defaultText = {
    errorMessage: "This field should not be blank",
    secondErrorMessage: "Please enter a valid email address",
    successMessage: "Registered Successfully and your email password sent to your mail",
    thirdErrorMessage: "Error signing up, please try again",
    register: "Register",
    welcomeMessage: "Welcome to Locaradar ",
    email: "Email",
    enterEmail: "Enter Email",
    continue: "Continue",
    accountHaveOrNot: "Already have an Account?",
    login: "Login",
 
 
   
  };

  const translatePageContent = async () => {
    const translations = await Promise.all(
      Object.keys(defaultText).map(async key => ({
        [key]: await translateText(defaultText[key])
      }))
    );

    const translated = Object.assign({}, ...translations);
    setTranslatedText(translated);
  };

  // const translatePageContent = async () => {
  //   const translated = {
  //     errorMessage: await translateText("This field should not be blank"),
  //     secondErrorMessage: await translateText("Please enter a valid email address"),
  //     successMessage: await translateText("Registered Successfully and your email password sent to your mail"),
  //     thirdErrorMessage: await translateText("Error signing up, please try again"),
  //     register: await translateText("Register"),
  //     welcomeMessage: await translateText("Welcome to Locaradar "),   
  //     email: await translateText("Email"),
  //     enterEmail: await translateText("Enter Email"),
  //     continue: await translateText("Continue"),
  //     accountHaveOrNot: await translateText("Already have an Account?"),
  //     login: await translateText("Login"),
   
  //   };
  //   setTranslatedText(translated);
  // };

  useEffect(() => {
    translatePageContent();
  }, [language]);

  const { setToken, setUser } = useStateContext();

  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email) {
      setError(translatedText.errorMessage);
      return;
    } else if (!emailRegex.test(email)) {
      setError(translatedText.secondErrorMessage);
      return;
    }

    try {
      const { data } = await axiosClient.post("/signup", { email });
      setUser(data.user);
      setToken(data.token);
      if (data.status === "false") {
        iziToast.error({
          message: data.message,
          position: "topCenter",
        });
      } else {
        iziToast.success({
          message:
            translatedText.successMessage,
          position: "topCenter",
        });

        navigate("/payment");
        // navigate("/otp", { state: { email } });
      }
    } catch (err) {
      const response = err.response;
      if (response && response.status === 422) {
        if (response.data.errors && response.data.errors.email) {
          setError(response.data.errors.email[0]);
        } else {
          setError(translatedText.thirdErrorMessage);
        }
      } else {
        setError(translatedText.thirdErrorMessage);
      }
      console.error(err);
    }
  };

  return (
    <>
      <Navbar />
      <Link to="/" className="float">
        <img src="images-local/help.png" alt="" />
      </Link>
      <div className="container-fluid location">
        <div className="container login">
          <div className="row">
            <div className="col-lg-6 login-left">
              <div className="l-logo">
                <img src="images-local/logo2.png" alt="" />
              </div>
              <h1>{translatedText.register} !</h1>
              <h6>{translateText.welcomeMessage}</h6>
              <form onSubmit={handleSubmit} className="login-form">
                <div className="login-item">
                  <label htmlFor="email">{translatedText.email}</label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    placeholder={translatedText.enterEmail}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
             
                {error && <p style={{ color: "#ff8780" }}>{error}</p>}

                <div className="login-btn">
          
                  <button type="submit">{translatedText.continue}</button>
                </div>
                <p className="register">
                  {translatedText.accountHaveOrNot} {" "}
                  <Link to="/login" className="text-decoration-none">
                    <span>{translatedText.login}</span>{" "}
                  </Link>
                </p>
              </form>
            </div>
            <div className="col-lg-6 login-img">
              <img src="images-local/phone.png" alt="" />
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Register;
